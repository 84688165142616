import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Organization, User} from '../../shared/models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private httpClient: HttpClient) {}

  private orgId: string;
  private apiURL = '/core/';

  organizationState$ = new BehaviorSubject<any>({});

  public getOrganization(): Observable<any> {
    return this.httpClient.get<Organization>(
      `${this.apiURL}organizations/detail/wl/`,
      {
        responseType: 'json',
        withCredentials: true,
      }
    );
  }

  getOrg(): void {
    this.getOrganization().subscribe({
      next: (res): void => {
        this.orgId = res.id;
        localStorage.setItem('organization', JSON.stringify(res));
        this.setOrganizationState(res);
      },
      complete: (): void => {
        window.location.href =
          `${environment.coreUrl}/login?orgId=` + this.orgId;
      },
    });
  }

  refreshFavicon(org: Organization): void {
    const link: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");
    if (link) {
      const timestamp = new Date().getTime();
      const newHref = `./favicon.ico?v=${org.theme?.lastUpdated || timestamp}`;
      link.href = newHref;
    }
  }

  setOrganizationState(payload: any): void {
    this.refreshFavicon(payload);
    return this.organizationState$.next(payload);
  }

  getOrganizationState(): Observable<User> {
    return this.organizationState$.asObservable();
  }
}
