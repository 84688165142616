import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

export class CustomMatPaginatorInt extends MatPaginatorIntl {
  constructor(
    private translateService: TranslateService,
    private translateParser: TranslateParser
  ) {
    super();
    this.getTranslations();
    this.translateService.onLangChange.subscribe(() => this.getTranslations());
  }

  getTranslations(): void {
    this.translateService
      .get([
        'paginator.items_per_page',
        'paginator.next_page',
        'paginator.previous_page',
        'paginator.last_page',
        'paginator.first_page',
        'paginator.range',
        'paginator.of_zero',
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['paginator.items_per_page'];
        this.nextPageLabel = translation['paginator.next_page'];
        this.previousPageLabel = translation['paginator.previous_page'];
        this.lastPageLabel = translation['paginator.last_page'];
        this.firstPageLabel = translation['paginator.first_page'];

        this.changes.next();
      });
  }

  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length == 0 || pageSize == 0) {
      return this.translateService.instant('paginator.of_zero', {length});
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return this.translateService.instant('paginator.range', {
      startIndex: startIndex + 1,
      endIndex,
      length,
    });
  };
}
