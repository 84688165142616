export const environment = {
  production: false,
  local: false,
  develop: true,
  fusionChartsKey:
    'OuB3exvA8C7C3E6A5C5D3H3I3B8B1B4B1udnE4H1cgkA-13lB4A3C-7izA5C5B3D6F5F4E4E1B9B5C3C4C4E1A2E1mllC7E2B4dD4F3H3yxoF4E2D3isbB6C1E3ycaC1G4B1B8B4A4B3D3B4H3A33fhhD8B1SG4lhJ-7A9C11A5veE3NA1A1sslE2D6G2F3H3J3B8A5A4C3F4B2E1H4z==',
  fullApiUrl: 'https://api.adclicks.dev',
  apiUrl: '/api/v2/',
  coreUrl: 'https://core.adclicks.dev',
};
