import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  // Body loading
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public readonly loading$: Observable<boolean> = this.loading.asObservable();

  // Small loading
  private smallLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}

  show(type: string): void {
    switch (type) {
      case 'body':
        setTimeout((): void => {
          this.loading.next(true);
        }, 0);
        break;
      case 'small':
        setTimeout((): void => {
          this.smallLoading.next(true);
        }, 0);
        break;
    }
  }

  hide(type: string): void {
    switch (type) {
      case 'body':
        setTimeout((): void => {
          this.loading.next(false);
        }, 0);
        break;
      case 'small':
        setTimeout((): void => {
          this.smallLoading.next(false);
        }, 0);
        break;
    }
  }
}
