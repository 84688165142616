import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  CustomError,
  Organization,
  Report,
  ReportPage,
  SharedAuth,
} from '../../shared/models';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {handleError} from '../errors';

@Injectable({
  providedIn: 'root',
})
export class ShareReportService {
  private baseURL = '/share-reports/';

  constructor(private httpClient: HttpClient) {}

  /* Public Endpoints */
  public getShareAuth(sharedId: string): Observable<SharedAuth | CustomError> {
    const headers = new HttpHeaders().set('skip', 'true');

    return this.httpClient
      .get<SharedAuth>(`${environment.fullApiUrl}${this.baseURL}${sharedId}`, {
        headers,
      })
      .pipe(catchError(handleError.bind(this)));
  }

  public getSharedReport(
    sharedAuth: SharedAuth
  ): Observable<Report | CustomError> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient
      .get<Report>(
        `${environment.fullApiUrl}${this.baseURL}report/${sharedAuth?.reportId}`,
        {headers}
      )
      .pipe(catchError(handleError.bind(this)));
  }

  public getSharedPage(
    reportId: string,
    pageId: string,
    sharedAuth: SharedAuth
  ): Observable<ReportPage | CustomError> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient
      .get<ReportPage>(
        `${environment.fullApiUrl}${this.baseURL}report/${reportId}/pages/${pageId}/get-page`,
        {headers}
      )
      .pipe(catchError(handleError.bind(this)));
  }

  public getPictureByPage(
    reportId: string,
    pageId: string,
    sharedAuth: SharedAuth
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient.get(
      `${environment.fullApiUrl}${this.baseURL}report/${reportId}/get-background-image/${pageId}`,
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  public getImage(
    name: string,
    type: string,
    sharedAuth: SharedAuth,
    id?: string,
    userId?: string
  ): Observable<Blob> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient.get(`${environment.fullApiUrl}/api/v2/file/get/`, {
      params: {
        image: name,
        type,
        id: id ?? false,
        userId: userId ?? false,
      },
      headers,
      responseType: 'blob',
    });
  }

  public getOrganization(
    sharedAuth: SharedAuth
  ): Observable<Organization | CustomError> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient
      .get<Organization>(`${environment.fullApiUrl}${this.baseURL}wl/`, {
        headers,
      })
      .pipe(catchError(handleError.bind(this)));
  }

  public previewData(
    reportId: string,
    pageId: string,
    payload: ReportPage,
    sharedAuth: SharedAuth
  ): Observable<ReportPage | CustomError> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient
      .post<ReportPage>(
        `${environment.fullApiUrl}${this.baseURL}report/${reportId}/pages/${pageId}/preview/`,
        payload,
        {
          headers,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError.bind(this)));
  }

  public downloadReport(
    reportId: string,
    sharedAuth: SharedAuth
  ): Observable<{pdf: string} | any> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient
      .get<{pdf: string} | any>(
        `${environment.fullApiUrl}${this.baseURL}report/${reportId}/generate-pdf`,
        {headers}
      )
      .pipe(catchError(handleError.bind(this)));
  }

  public checkPdfStatus(
    reportId: string,
    pdfId: string,
    sharedAuth: SharedAuth
  ): Observable<Blob> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .set('share-id', `${sharedAuth?.shareId}`);

    return this.httpClient.get(
      `${environment.fullApiUrl}${this.baseURL}report/${reportId}/check-pdf/${pdfId}`,
      {
        params: {},
        headers,
        responseType: 'blob',
      }
    );
  }
}
