import {AbstractControl} from '@angular/forms';
import {ScopeField} from '../models';

export function ValidateAdDatasetFields(control: AbstractControl): any {
  const dataset = control?.value;
  const fields: ScopeField[] = [];

  if (!dataset.scopes) {
    return;
  }

  for (const scope of dataset.scopes) {
    for (const scopeField of scope.fields) {
      const firstMatch = fields.find((elem) => elem.id === scopeField.id);
      if (!firstMatch) {
        fields.push(scopeField);
      }
    }
  }

  const isValid = fields?.some((field) => field.id === 'adPreview');
  return isValid ? null : {invalidDataset: true};
}
