import { HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CustomError} from '../../shared/models';

export function handleError(
  error: Error | HttpErrorResponse
): Observable<CustomError> {
  const customError: CustomError = {
    message: 'Something went wrong!',
  };

  if (error instanceof HttpErrorResponse) {
    // Server error
    customError.message = navigator.onLine
      ? error?.error?.error?.message ||
        error?.error?.message ||
        error?.message ||
        'Something went wrong!'
      : 'No Internet Connection';
    customError.status = error?.status;
  } else {
    // Client Error
    customError.message = error?.message ? error.message : error.toString();
  }

  return throwError(customError);
}
