import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  private apiURL = environment.apiUrl;
  private fullApiUrl = environment.fullApiUrl;

  public getUsers(
    showAll = false,
    inOrg = true,
    filter?: string,
    page?: number,
    pageSize?: number,
    sortBy?: string
  ): Observable<any> {
    let params: Params;

    if (showAll) {
      params = {
        showAll,
        checkDataAccess: true,
        inOrg,
        sortBy,
        page,
        limit: pageSize,
        filter,
      };
    } else {
      params = {
        showAll,
        inOrg,
      };
    }
    return this.httpClient.get<any>(`${this.apiURL}users`, {
      params,
      responseType: 'json',
    });
  }

  public changeExternalUserPassword(payload: {id: string}): Observable<any> {
    return this.httpClient.post<any>(
      `/core/request-password-change-external/`,
      payload
    );
  }

  public resendWelcomeEmailToExternalUser(payload: {
    id: string;
  }): Observable<any> {
    return this.httpClient.post<any>(`/core/resendWelcomeEmail`, payload);
  }
}
