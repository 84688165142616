import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Dataset, Scope} from 'src/app/shared/models/dataset.model';
import {environment} from 'src/environments/environment';
import {ApiService} from './api.service';
import {TableDataResponse} from 'src/app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ReportingDatasetsService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  private baseURL = environment.apiUrl;
  private reportDataset = 'reporting-dataset/';

  public getAvailableFields(payload): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}connections/get-available-fields`,
      payload
    );
  }

  public getAvailableScopes(payload): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}connections/get-available-scopes`,
      payload
    );
  }

  public getDataset(id): Observable<Dataset> {
    return this.http.get<Dataset>(
      `${this.baseURL + this.reportDataset + id}/`,
      {responseType: 'json'}
    );
  }

  public getAllDatasets(): Observable<Dataset[]> {
    return this.apiService.get(`${this.baseURL + this.reportDataset}`);
  }

  public getPaginatedDatasets(
    page?,
    limit?
  ): Observable<TableDataResponse<Dataset>> {
    const params = {
      page,
      limit,
    };
    return this.http.get<TableDataResponse<Dataset>>(
      `${this.baseURL}reporting-dataset/`,
      {responseType: 'json', params}
    );
  }

  public createNewDataset(payload): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL + this.reportDataset}`,
      payload,
      {responseType: 'json'}
    );
  }

  public updateDataset(payload, dsId): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL + this.reportDataset + dsId}`,
      payload,
      {responseType: 'json'}
    );
  }

  public deleteDataset(id): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}reporting-dataset/${id}/`);
  }

  public newScope(payload, dsId): Observable<Scope> {
    return this.http.post<Scope>(
      `${this.baseURL}reporting-dataset/${dsId}/new-scope/`,
      payload,
      {responseType: 'json'}
    );
  }

  public updateScope(payload, dsId): Observable<Scope> {
    return this.http.post<Scope>(
      `${this.baseURL}reporting-dataset/${dsId}/update-scope/`,
      payload,
      {responseType: 'json'}
    );
  }

  public removeScope(payload, dsId): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL}reporting-dataset/${dsId}/remove-scope/`,
      payload,
      {responseType: 'json'}
    );
  }

  public newField(payload, dsId): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL}reporting-dataset/${dsId}/new-field/`,
      payload,
      {responseType: 'json'}
    );
  }

  public updateField(payload, dsId): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL}reporting-dataset/${dsId}/update-field/`,
      payload,
      {responseType: 'json'}
    );
  }

  public removeField(payload): Observable<Dataset> {
    return this.http.post<Dataset>(
      `${this.baseURL}reporting-dataset/remove-field/`,
      payload,
      {responseType: 'json'}
    );
  }

  public getCustomCalculations(params): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}reporting-dataset/custom-expressions/all/`,
      {responseType: 'json', params}
    );
  }
  public getCustomCalculation(datasetId, id): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}reporting-dataset/custom-expressions/get-single/`,
      {responseType: 'json', params: {datasetId, id}}
    );
  }

  public convertExpressions(payload): Observable<object> {
    return this.http.post<object>(
      `${this.baseURL}reporting/custom-expression/convert/`,
      payload,
      {responseType: 'json'}
    );
  }
}
