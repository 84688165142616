import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {
  AWSData,
  FTPData,
  SFData,
} from 'src/app/shared/models/import-data.model';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  public apiURL = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  // When uploading CSV File
  getCSVMetadata(payload: any): Observable<any> {
    const formData = new FormData();
    formData.append('csv', payload);
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/csv/metadata/`,
      formData,
      {reportProgress: true, responseType: 'json', observe: 'events'}
    );
  }

  // When using FTP Connection
  getFTPMetadata(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/ftp-csv/metadata/`,
      payload,
      {responseType: 'json'}
    );
  }

  // When using HTTP Connection
  getHTTPMetadata(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/http-csv/metadata/`,
      payload
    );
  }

  // When using AWS Connection
  getAWSMetadata(payload: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/s3-csv/metadata/`,
      payload
    );
  }

  // When using Salesforce Report search
  getSFReportMetadata(query: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiURL}integration/salesforce/report`,
      {params: {filter: `name contains ${query}`}}
    );
  }

  // When using SOQL query
  getSOQLMetadata(query: string): Observable<any> {
    const queryString = {
      query,
    };

    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/sf/metadata/soql`,
      queryString
    );
  }

  // When using Report
  getReportMetadata(reportId: string): Observable<any> {
    const payload = {
      reportId,
    };

    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/sf/metadata/report`,
      payload
    );
  }

  // Returns Import History
  getSyncLogs(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}sync-tools/logs`);
  }

  // Returns Scheduled Actions
  getAllTools(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}sync-tools/org-tools`);
  }

  // Create Scheculed Action
  createTool(payload: FTPData): Observable<FTPData> {
    return this.httpClient.post<FTPData>(
      `${this.apiURL}sync-tools/ftp-csv/`,
      payload
    );
  }

  // Create Scheculed Action
  updateTool(
    payload: FTPData,
    toolId: string,
    importType: string
  ): Observable<FTPData> {
    return this.httpClient.post<FTPData>(
      `${this.apiURL}sync-tools/${importType}/${toolId}/`,
      payload
    );
  }

  // Create Scheculed Action
  executeCSV(payload: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/csv/execute/`,
      payload
    );
  }
  // Execute Sf sync Action
  executeSf(toolId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/sf/execute/${toolId}/`,
      null
    );
  }

  // Delete Action
  deleteTool(toolId: string): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}sync-tools/${toolId}/`);
  }

  // Get Single Record
  getTool(toolId: string): Observable<FTPData> {
    return this.httpClient.get<FTPData>(`${this.apiURL}sync-tools/${toolId}`);
  }

  // Create HTTP Import
  createHTTPTool(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${this.apiURL}sync-tools/`, payload);
  }

  // Create HTTP Import
  updateHTTPTool(payload: any, toolId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/${toolId}`,
      payload
    );
  }

  // Create SOQL Import
  createSOQLTool(payload: SFData): Observable<SFData> {
    return this.httpClient.post<SFData>(`${this.apiURL}sync-tools/`, payload);
  }

  // Update SOQL Action
  updateSOQLTool(payload: any, toolId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiURL}sync-tools/${toolId}/`,
      payload
    );
  }

  // Create AWS Import
  createAWSTool(payload: AWSData): Observable<AWSData> {
    return this.httpClient.post<AWSData>(`${this.apiURL}sync-tools/`, payload);
  }

  // Update AWS Action
  updateAWSTool(payload: AWSData, toolId: string): Observable<AWSData> {
    return this.httpClient.post<AWSData>(
      `${this.apiURL}sync-tools/${toolId}/`,
      payload
    );
  }

  // Share adinsights report to Salesforce
  shareToCrm(payload): Observable<any> {
    return this.httpClient.post<any>(`${this.apiURL}sync-tools/`, payload);
  }

  // Get CRM report scheduled
  getCRMSchedule(syncToolId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}sync-tools/${syncToolId}`);
  }
}
