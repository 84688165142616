import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  ReportTheme,
  Widget,
  WidgetTemplate,
  ReportPage,
  WidgetInfo,
  WidgetDelete,
  TextData,
  IWidgetContextMenu,
} from 'src/app/shared/models';
import {ApiService} from './api.service';
import {environment} from '../../../environments/environment';
import {GridsterItem} from 'angular-gridster2';

export interface ChangeDynamicTextSubject {
  isInitialValue: boolean;
  from: 'reporting' | 'editor';
  widgetId: string;
  newText?: TextData;
  widget?: Widget;
}

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private baseURL = `${environment.apiUrl}reporting/`;

  constructor(private apiService: ApiService) {}

  // Widget to Page communication
  private editModeSource = new Subject<boolean>();
  private isEditMode = false;
  editModeStream$ = this.editModeSource.asObservable();

  private saveSubject = new Subject<boolean>();
  save$ = this.saveSubject.asObservable();

  private saveFinishedSubject = new Subject<boolean>();
  saveFinished$ = this.saveFinishedSubject.asObservable();

  private addWidgetSource = new Subject<any>();
  addWidgetStream$ = this.addWidgetSource.asObservable();

  private addWidgetInGrid = new Subject<any>();
  addWidgetGridStream$ = this.addWidgetInGrid.asObservable();

  private deleteWidgetSource = new Subject<any>();
  deleteWidgetStream$ = this.deleteWidgetSource.asObservable();

  private saveWidgetSource = new Subject<any>();
  saveWidgetStream$ = this.saveWidgetSource.asObservable();

  private duplicateWidgetSource = new Subject<any>();
  duplicateWidgetStream$ = this.duplicateWidgetSource.asObservable();

  private addTemplateWidgetSource = new Subject<any>();
  templateWidgetStream$ = this.addTemplateWidgetSource.asObservable();

  private moveWidget = new Subject<GridsterItem>();
  moveWidget$ = this.moveWidget.asObservable();

  private widgetHasBeenEdited = new BehaviorSubject<Widget | null>(null);
  widgetHasBeenEdited$ = this.widgetHasBeenEdited.asObservable();

  private widgetContextMenu = new Subject<IWidgetContextMenu | null>();
  widgetContextMenu$ = this.widgetContextMenu.asObservable();

  private changeDynamicText = new BehaviorSubject<ChangeDynamicTextSubject>({
    isInitialValue: true,
    from: 'reporting',
    widgetId: '',
    newText: {innerHtml: '', originalHtml: ''},
  });
  changeDynamicText$ = this.changeDynamicText.asObservable();

  private editTitleWidgetTitle = new BehaviorSubject<{
    isEdit: boolean;
    widgetId: string;
  }>({
    isEdit: false,
    widgetId: '',
  });

  editTitleWidgetTitle$ = this.editTitleWidgetTitle.asObservable();

  public set setSaveValue(value: boolean) {
    this.saveSubject.next(value);
  }

  setChangeDynamicTextValue(value: ChangeDynamicTextSubject): void {
    this.changeDynamicText.next(value);
  }

  /**
   *
   * @param isEdit
   * @param widgetId
   */
  setEditTitleWidgetTitle(isEdit: boolean, widgetId: string): void {
    this.editTitleWidgetTitle.next({isEdit, widgetId});
  }

  cascadeEditMode(enabled: boolean): void {
    this.isEditMode = enabled;
    this.editModeSource.next(enabled);
  }

  getEditMode(): boolean {
    return this.isEditMode;
  }

  cascadeAddWidget(widgetInfo: WidgetInfo): void {
    this.addWidgetSource.next(widgetInfo);
  }

  cascadeAddWidgetGrid(widget: Widget): void {
    this.addWidgetInGrid.next(widget);
  }

  cascadeDeleteWidget(widgetInfo: any): void {
    this.deleteWidgetSource.next(widgetInfo);
  }

  cascadeSaveWidget(widgetInfo: any): void {
    this.saveWidgetSource.next(widgetInfo);
  }

  cascadeDuplicateWidget(widgetInfo: any): void {
    this.duplicateWidgetSource.next(widgetInfo);
  }

  cascadeAddTemplateWidget(widgetInfo: any): void {
    this.addTemplateWidgetSource.next(widgetInfo);
  }

  finishSavingPage(): void {
    this.saveFinishedSubject.next(true);
  }

  onMoveWidget(item: GridsterItem): void {
    this.moveWidget.next(item);
  }

  onWidgetHasBeenEdited(widget: Widget | null): void {
    this.widgetHasBeenEdited.next(widget);
  }

  onContextMenu(value: IWidgetContextMenu | null): void {
    this.widgetContextMenu.next(value);
  }

  // HTTP Calls
  // tslint:disable-next-line:max-line-length
  public getSampleWidget(
    reportId: string,
    pageId: string,
    widgetType: string,
    theme: ReportTheme,
    pageWidgets?: Widget[]
  ): Observable<Widget> {
    const payload = {
      chartType: widgetType,
      theme,
      reportId,
      pageId,
      pageWidgets,
    };
    const path = `${this.baseURL}get-dummy-widget/`;
    return this.apiService.post(path, payload);
  }

  public saveWidgetTemplate(payload): Observable<Widget> {
    const path = `${this.baseURL}templates/widget-templates/`;
    return this.apiService.post(path, payload);
  }

  public getWidgetTemplate(templateId: string): Observable<WidgetTemplate> {
    const path = `${this.baseURL}templates/widget-templates/${templateId}/`;
    return this.apiService.get(path);
  }

  public getWidgetTemplates(params: any): Observable<any> {
    const path = `${this.baseURL}templates/widget-templates/`;
    return this.apiService.get(path, params);
  }

  public deleteWidgetTemplate(templateId): Observable<any> {
    const path = `${this.baseURL}templates/widget-templates/${templateId}`;
    return this.apiService.delete(path);
  }

  public deleteWidgets(
    reportId,
    pageId,
    widgets: WidgetDelete[]
  ): Observable<any> {
    const payload = {widgets};
    const path = `${this.baseURL}delete-widgets/`;
    return this.apiService.post(path, payload);
  }

  public updatePageWidgets(reportId, pageId, payload): Observable<ReportPage> {
    const path = `${this.baseURL}${reportId}/pages/${pageId}/update-widgets/`;
    return this.apiService.post(path, payload);
  }

  public duplicateWidget(reportId, pageId, widgetId): Observable<any> {
    const path = `${this.baseURL}${reportId}/pages/${pageId}/widgets/${widgetId}/duplicate`;
    return this.apiService.get(path);
  }

  public previewData(reportId, pageId, payload): Observable<any> {
    const path = `${this.baseURL}${reportId}/pages/${pageId}/preview/`;
    return this.apiService.post(path, payload);
  }

  public getPaginatedData(reportId, pageId, payload): Observable<any> {
    const path = `${this.baseURL}${reportId}/pages/${pageId}/get-table/`;
    return this.apiService.post(path, payload);
  }
}
