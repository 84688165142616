import {ConnectedPosition} from '@angular/cdk/overlay';
import {IConnectionVideo, SidenavLink, Widget} from '../models';

/**
 * Check if a variable is an array.
 * @param value Possible array.
 */
export function checkIfArray<T>(value: T[] | undefined): boolean {
  return Array.isArray(value);
}

/**
 * Open the whole page in fullscreen mode.
 * @param {string} elementId - The element you want displayed in fullscreen mode.
 */
export const openFullscreen = (elementId: string): void => {
  const elem = document.getElementById(elementId) as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
  };
  elem.style.overflowY = 'auto';
  if (elem?.requestFullscreen) {
    elem?.requestFullscreen().then();
  } else if (elem?.mozRequestFullScreen) {
    elem?.mozRequestFullScreen();
  } else if (elem?.webkitRequestFullscreen) {
    elem?.webkitRequestFullscreen();
  } else if (elem?.msRequestFullscreen) {
    elem?.msRequestFullscreen();
  }
};

/**
 * Used to set the Widget Actions menu on the widget.
 */
export const getPositionPairs = (): Array<ConnectedPosition> => {
  return [
    {
      // Right Position Down
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -2,
      offsetY: 2,
    },
    {
      // Left Position
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      // Top Right Down
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 2,
      offsetX: -2,
    },
    {
      // Right Position Up
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetY: -2,
      offsetX: -2,
    },
  ];
};

export const getValidThemeKeys = (): Array<string> => [
  'name',
  'pageColor',
  'backgroundColor',
  'titleColor',
  'bodyColor',
  'borderColor',
  'tableHeaderBgColor',
  'tableHeaderBorderColor',
  'tableRowBgColor',
  'tableRowBorderColor',
  'stripedRows',
  'hideBorders;',
  'colors',
  'colorPalette',
  'invertAdStyle',
  'alignItems',
  'direction',
  'fontSize',
  'logo',
  'shapeClass',
  'transparency',
  'backgroundImage',
  'backgroundImageFile',
  'customThemeBackgroundImage',
  'websiteColor',
  'schemaIndicator',
  'labelBold',
  'labelItalic',
  'labelAlign',
];

export const getSidenavLinks = (): Array<SidenavLink> => {
  return [
    {
      icon: {class: 'fa-house'},
      path: 'home',
      translation: 'sidenav.links.home',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
        'ROLE_EXTERNAL',
      ],
      showMobile: true,
      isBeta: false,
    },
    {
      icon: {class: 'fa-building'},
      path: 'clients',
      translation: 'sidenav.links.companies',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: true,
      isBeta: false,
    },
    {
      icon: {class: 'fa-toolbox'},
      path: 'monitoring',
      translation: 'sidenav.links.monitoring',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: false,
      isBeta: true,
    },
    {
      icon: {class: 'fa-folders'},
      path: 'templates',
      translation: 'sidenav.links.templates',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: false,
      isBeta: false,
    },
    {
      icon: {class: 'fa-chart-network'},
      path: 'connections',
      translation: 'sidenav.links.connections',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: true,
      isBeta: false,
    },
    {
      icon: {class: 'fa-swatchbook'},
      path: 'themes',
      translation: 'sidenav.links.themes',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: false,
      isBeta: false,
    },
    {
      icon: {class: 'fa-gear'},
      path: 'settings',
      translation: 'sidenav.links.settings',
      roles: [
        'ROLE_CLIENT',
        'ROLE_ORG_ADMIN',
        'ROLE_ADMIN',
        'ROLE_REPORTS',
        'ROLE_IMPERSONATE',
      ],
      showMobile: true,
      isBeta: false,
    },
  ];
};

export const getFrequencies = (): {label: string; id: string}[] => {
  return [
    {
      label: 'shared.dialogs.share_report.frequency_now',
      id: 'Now',
    },
    {
      label: 'shared.dialogs.share_report.frequency_daily',
      id: 'Daily',
    },
    {
      label: 'shared.dialogs.share_report.frequency_weekly',
      id: 'Weekly',
    },
    {
      label: 'shared.dialogs.share_report.frequency_monthly',
      id: 'Monthly',
    },
  ];
};

/**
 * List of available hours for email notifications.
 */
export const getHours = (): string[] => {
  return [
    '12AM',
    '1AM',
    '2AM',
    '3AM',
    '4AM',
    '5AM',
    '6AM',
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM',
    '6PM',
    '7PM',
    '8PM',
    '9PM',
    '10PM',
    '11PM',
  ];
};

/**
 * List of available days for email notifications.
 */
export const getWeekDays = (): {label: string; id: string}[] => {
  return [
    {
      label: 'shared.dialogs.share_report.weekday_sunday',
      id: 'Sunday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_monday',
      id: 'Monday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_tuesday',
      id: 'Tuesday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_wednesday',
      id: 'Wednesday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_thursday',
      id: 'Thursday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_friday',
      id: 'Friday',
    },
    {
      label: 'shared.dialogs.share_report.weekday_saturday',
      id: 'Saturday',
    },
  ];
};

export const getDaysInMonth = (year, month): number[] => {
  const monthIndex: number = month - 1;
  const date: Date = new Date(year, monthIndex, 1);
  const result: number[] = [];

  while (date.getMonth() == monthIndex) {
    result.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }

  return result;
};

/**
 * Generate a unique file name and remove special characters.
 *
 * @param {string} name The name of the file.
 * @param {string} format The file format.
 * @returns {string} The generated file name.
 */
export const generateFilename = (name = 'filename', format: string): string => {
  const replacedName: string = name.replace(/[<>:"/\\|?*\s]/g, '_');
  return `${replacedName}.${format}`;
};

/**
 * Downloads a file from the provided data URL with the specified file name.
 *
 * @param data {string} - The data URL representing the file to be downloaded.
 * @param fileName {string} - The desired name of the downloaded file.
 */
export const downloadURL = (data: string, fileName: string): void => {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

/**
 * Checks if all properties of objects in an array are not null, undefined, empty strings, or empty arrays.
 *
 * The function checks if all values in each object's properties are not:
 * - `null` or `undefined`
 * - Empty strings (after trimming whitespace)
 * - Empty arrays
 *
 * @template T - The type of objects in the array.
 * @param {T[]} objects - An array of objects to be checked.
 * @returns {boolean} `true` if all properties of all objects meet the criteria, `false` otherwise.
 */
export const allPropertiesAreNotNullNorUndefined = <T>(
  objects: T[]
): boolean => {
  return objects.every((obj: any) =>
    Object.values(obj).every(
      (value) =>
        value !== null &&
        value !== undefined &&
        !(typeof value === 'string' && value.trim() === '') &&
        !(Array.isArray(value) && value.length === 0)
    )
  );
};

export const isObjectEmpty = (object: any): boolean => {
  return Object.keys(object).length === 0;
};

/**
 * Checks if the total character length of an array of strings exceeds a specified limit.
 *
 * @param {string[]} characterArray - An array of strings to calculate the character length from.
 * @param {number} characterLimit - The maximum allowed character length.
 * @returns {boolean} - Returns `true` if the total character length exceeds the limit, otherwise `false`.
 */
export const checkCharacterLimit = (
  characterArray: string[],
  characterLimit: number
): boolean => {
  const characterSum: number = characterArray.reduce(
    (sum: number, text: string) => sum + text.length,
    0
  );
  return characterSum > characterLimit;
};

/**
 * Checks if a given string is a valid date.
 *
 * @param dateString - The string representation of the date to be validated.
 * @returns `true` if the string is a valid date; otherwise, `false`.
 */
export const isValidDate = (dateString: string): boolean => {
  return !isNaN(Date.parse(dateString));
};

/**
 * Extracts the base URL from a given URL, excluding any parameters or hashes.
 * Additionally, omits specified URLs from the extraction.
 *
 * @param url - The input URL from which to extract the base URL.
 * @param urlsToOmit - An array of URLs to omit from the extraction.
 * @returns The base URL without any parameters or hashes, omitting specified URLs.
 */
export const getPlainUrl = (url: string, urlsToOmit: string[] = []): string => {
  const baseUrl = url.split(/[?#]/)[0];
  const shouldOmit = urlsToOmit.some((omitUrl: string) =>
    url.includes(omitUrl)
  );
  return shouldOmit ? url : baseUrl;
};

export const getConnectionVideos = (): IConnectionVideo[] => {
  return [
    {
      provider: 'facebook-ads',
      url: 'https://www.youtube.com/embed/sB1Z_e1sI1M?si=JUY-lKATS5M_jhx9',
    },
    {
      provider: 'adwords',
      url: 'https://www.youtube.com/embed/HNVvqvi8cMw?si=uAgArDrTjM5p8i79',
    },
    {
      provider: 'bing',
      url: 'https://www.youtube.com/embed/fqdZC4LaETo?si=4O4lo5tNffmDXVED',
    },
    {
      provider: 'google-analytics',
      url: 'https://www.youtube.com/embed/OaM3_u2RYJI?si=W78sh1kVbQYIgMPL',
    },
    {
      provider: 'google-analytics-v4',
      url: 'https://www.youtube.com/embed/OaM3_u2RYJI?si=W78sh1kVbQYIgMPL',
    },
    {
      provider: 'google-search-console',
      url: 'https://www.youtube.com/embed/6VjbSqt3PGc?si=AxmfRtNRCFBtEZUe',
    },
    {
      provider: 'facebook-insights',
      url: 'https://www.youtube.com/embed/FMJmRQWBLQ0?si=GXjEB0FzNEvjuxUO',
    },
    {
      provider: 'instagram-insights',
      url: 'https://www.youtube.com/embed/GVylVMRK5r4?si=8NaL-5QEGragKpEI',
    },
    {
      provider: 'call-rail',
      url: 'https://www.youtube.com/embed/xerLyOKiYeQ?si=eLmNaIxXv2BPUpPP',
    },
  ];
};

export type TableDataResponse<T> = {
  data: Array<T>;
  total: number;
};

export const setWidgetFontSize = (
  isPresentationMode: boolean,
  widget: Widget
): void => {
  const chartData =
    widget.chartData?.chartData?.chart || widget.chartData?.chart;

  if (isPresentationMode && chartData) {
    chartData.baseFontSize = '18';
    chartData.xAxisNameFontSize = '18';
    chartData.yAxisNameFontSize = '18';
    chartData.sYAxisNameFontSize = '18';
    chartData.legendIconScale = '1.5';
    chartData.legendItemFontSize = '18';
  } else {
    delete chartData?.baseFontSize;
    delete chartData?.xAxisNameFontSize;
    delete chartData?.yAxisNameFontSize;
    delete chartData?.sYAxisNameFontSize;
    delete chartData?.legendIconScale;
    delete chartData?.legendItemFontSize;
  }
};

/**
 * Escapes all double quotation marks and commas inside a string and encloses the entire string in double quotation marks.
 *
 * @param {string} text - The input string to process.
 * @returns {string} The processed string.
 */
export const setCharacterEscape = (text: string): string => {
  const escapedString = text.replace(/["|,]/g, '\\$&');
  return `"${escapedString}"`;
};

export const propertiesToCopy = (): string[] => {
  return [
    'alignment',
    'shapeType',
    'cornerType',
    'darkMode',
    'placeValuesInside',
    'valueToShow',
    'hideYAxisName',
    'hideXAxisName',
    'showPercentage',
    'showAxis',
    'legendPosition',
    'dataValue',
    'dataLabel',
    'dataPlot',
    'brandingStyle',
    'showCumulative',
  ];
};
