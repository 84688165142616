import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'formatLanguage',
    pure: false,
    standalone: true,
})
export class FormatLanguagePipe implements PipeTransform {
  constructor(
    public translate: TranslateService,
    private ref: ChangeDetectorRef
  ) {}

  transform(value: string): string {
    let languageTranslated = '';
    switch (value) {
      case 'es':
      case 'ES':
        languageTranslated = this.translate.instant('common.languages.spanish');
        break;
      case 'en':
      case 'EN':
        languageTranslated = this.translate.instant('common.languages.english');
        break;
      case 'fr':
      case 'FR':
        languageTranslated = this.translate.instant('common.languages.french');
        break;
      default:
        languageTranslated = this.translate.instant('common.languages.english');
        break;
    }
    return languageTranslated;
  }
}
