import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

import {Response} from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private http: HttpClient = inject(HttpClient);

  constructor() {}

  baseUrl = `${environment.apiUrl}payment`;

  public getBillingHistory(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}`, {responseType: 'json'});
  }

  public getPaymentLink(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}/link`, {
      responseType: 'json',
    });
  }

  public getUpdatePaymentInformationLink(): Observable<Response> {
    return this.http.get<Response>(
      `${this.baseUrl}/update-billing-information-link`,
      {responseType: 'json'}
    );
  }

  public getPaymentSettings(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}/settings`, {
      responseType: 'json',
    });
  }

  public getPaymentDetails(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}/details`, {
      responseType: 'json',
    });
  }

  public getInvoice(paymentId: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/id/${paymentId}/invoice`, {
      responseType: 'blob',
    });
  }

  public cancelPlan(payload): Observable<Response> {
    return this.http.put<Response>(`${this.baseUrl}/cancel`, payload, {
      responseType: 'json',
    });
  }

  public changePlan(payload): Observable<Response> {
    return this.http.put<Response>(`${this.baseUrl}/change-plan`, payload, {
      responseType: 'json',
    });
  }

  public signUpAfterCancel(payload): Observable<Response> {
    return this.http.post<Response>(`${this.baseUrl}/signup-plan`, payload, {
      responseType: 'json',
    });
  }

  public getPaymentProducts(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}/products`, {
      responseType: 'json',
    });
  }

  public createSecondaryOrder(extraClients): Observable<Response> {
    const payload = {
      extraClients: extraClients,
    };
    return this.http.post<Response>(
      `${this.baseUrl}/create-secondary-order/`,
      payload,
      {responseType: 'json'}
    );
  }

  public chargeExtraClients(): Observable<Response> {
    return this.http.post<Response>(`${this.baseUrl}/charge-extra-clients/`, {
      responseType: 'json',
    });
  }
}
