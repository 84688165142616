import {inject, Injectable} from '@angular/core';
import {formatCurrency, formatNumber, formatPercent} from '@angular/common';
import {formatNumberCompactFunction, transformDateFunction} from '../pipes';

import {DynamicDate} from '../models';
import {PlanType} from '../enums';
import {BillingPlanStore} from '../state';
import {ClassicEditor} from 'ckeditor5';

export interface CommonLanguage {
  translation: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  // Store
  private billingPlanStore = inject(BillingPlanStore);
  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  constructor() {}

  private getCurrencySymbol(locale: string, currencyCode: string): string {
    const parts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).formatToParts();
    const currencyPart = parts.find((part) => part.type === 'currency');
    return currencyPart ? currencyPart.value : currencyCode;
  }

  public updateMetricData(
    type: string,
    value: number,
    currency?: string,
    shortenNumbers?: boolean
  ): string {
    let formattedData: string;
    switch (type) {
      case 'currency':
        if (shortenNumbers) {
          const currencySymbol = `${this.getCurrencySymbol(
            'en-US',
            currency || 'USD'
          )}`;
          formattedData = `${currencySymbol}${formatNumberCompactFunction(
            value
          )}`;
        } else {
          const currencySymbol = `${this.getCurrencySymbol(
            this.userLocale,
            currency || 'USD'
          )}`;
          formattedData = formatCurrency(
            value,
            this.userLocale,
            currencySymbol,
            currency || 'USD'
          ) as string;
        }
        break;
      case 'float':
      case 'integer':
      case 'decimal':
        if (shortenNumbers) {
          formattedData = formatNumberCompactFunction(value);
        } else {
          formattedData = formatNumber(
            value,
            this.userLocale,
            '1.0-2'
          ) as string;
        }
        break;
      case 'percentage':
        formattedData = formatPercent(value, this.userLocale, '1.2') as string;
        break;
      case 'time':
        formattedData = transformDateFunction(value);
        break;
      default:
        formattedData = value?.toString();
        break;
    }
    return formattedData;
  }

  public getDynamicDates(): DynamicDate[] {
    return [
      {
        name: 'Date',
        value: 'date',
        combinations: this.createCombinations('date'),
        pipeFormat: 'yyyy-MM-dd',
      },
      {
        name: 'Day of the Week',
        value: 'shortDay',
        combinations: this.createCombinations('shortday'),
        pipeFormat: 'EE',
      },
      {
        name: 'Day of the Week',
        value: 'longDay',
        combinations: this.createCombinations('longday'),
        pipeFormat: 'EEEE',
      },
      {
        name: 'Month, Day, Year',
        value: 'monthDayYear',
        combinations: this.createCombinations('monthdayyear'),
        pipeFormat: 'MMM dd, yyyy',
      },
      {
        name: 'Week',
        value: 'week',
        combinations: this.createCombinations('week'),
        pipeFormat: 'MMM dd - yy',
      },
      {
        name: 'Month',
        value: 'month',
        combinations: this.createCombinations('month'),
        pipeFormat: 'MMM',
      },
      {
        name: 'Month, Year',
        value: 'monthYear',
        combinations: this.createCombinations('monthyear'),
        pipeFormat: 'MMM yyyy',
      },
      {
        name: 'Month, Day',
        value: 'monthDay',
        combinations: this.createCombinations('monthday'),
        pipeFormat: 'MMM, dd',
      },
      {
        name: 'Year',
        value: 'year',
        combinations: this.createCombinations('year'),
        pipeFormat: 'yyyy',
      },
    ];
  }

  /**
   * Create date combinations based on the given value.
   * The function generates combinations for both current and previous date ranges.
   * @param value {string} - The value used to generate combinations (e.g., 'date', 'month', 'week').
   * @returns An array of date combinations including start, end, and both options for current and previous ranges.
   */
  private createCombinations(value: string): string[] {
    return [
      `current_${value}_start`,
      `current_${value}_end`,
      `current_${value}_both`,
      `previous_${value}_start`,
      `previous_${value}_end`,
      `previous_${value}_both`,
    ];
  }

  /**
   * Removes properties with falsy values from an object.
   * @template T - The type of the input and output object.
   * @param {T} payload - The object to be cleaned.
   * @returns {T} The cleaned object with falsy properties removed.
   */
  public clearPayload<T extends object>(payload: T): T {
    for (const key in payload) {
      if (!payload[key]) {
        delete payload[key];
      }
    }
    return payload;
  }

  public getUserPlatform(): string {
    let OS = 'Unknown';
    if (navigator.userAgent.indexOf('Win') != -1) {
      OS = 'Windows';
    }
    if (navigator.userAgent.indexOf('Mac') != -1) {
      OS = 'MacOS';
    }
    if (navigator.userAgent.indexOf('X11') != -1) {
      OS = 'UNIX';
    }
    if (navigator.userAgent.indexOf('Linux') != -1) {
      OS = 'Linux';
    }
    return OS;
  }

  public getSupportUrl(currentLang: string): string {
    return `https://help.adclicks.app/hc/${
      currentLang === 'es' ? 'es-419' : 'en-us'
    }`;
  }

  public copyObject(object: object): object {
    return JSON.parse(JSON.stringify(object));
  }

  public getCommonLanguage(): CommonLanguage[] {
    return [
      {
        translation: 'common.languages.english',
        value: 'EN',
      },
      {
        translation: 'common.languages.spanish',
        value: 'ES',
      },
      {
        translation: 'common.languages.french',
        value: 'FR',
      },
    ];
  }

  public insertStyleAttributeToAnchorTags(
    htmlText: string,
    styleToInsert: string
  ): string {
    const anchorPattern = /<a\s+([^>]*)>/g;
    return htmlText.replace(anchorPattern, (match: string, attrs) =>
      this.addStyleAttribute(match, attrs, styleToInsert)
    );
  }

  // Function to add the style attribute to the anchor tag
  private addStyleAttribute(
    match: string,
    attrs: string,
    styleValue: string
  ): string {
    attrs = attrs.trim();
    if (attrs) {
      attrs += ` style= ${styleValue}`;
    } else {
      attrs = styleValue;
    }
    return `<a ${attrs}>`;
  }

  /**
   * Get current user billing plan.
   * @returns {string} Current user plan.
   */
  public getUserPlan = (): PlanType => {
    const billingInfo = this.billingPlanStore.getPlan();
    const planType: string = billingInfo?.type?.toLowerCase() as string;

    const planTypeMap: {[key: string]: PlanType} = {
      'agency': PlanType.Agency,
      'inhouse': PlanType.InHouse,
      'freelance': PlanType.Freelance,
      'individual': PlanType.Individual,
      'basic': PlanType.Basic,
      'pro': PlanType.Pro,
      'premium': PlanType.Premium,
      'enterprise': PlanType.Enterprise,
      'free': PlanType.Free,
    };

    return planType in planTypeMap ? planTypeMap[planType] : PlanType.Free;
  };

  public forcePasteAsPlainText(editor: ClassicEditor): void {
    editor.editing.view.document.on('clipboardInput', (evt, data) => {
      const plainText = data.dataTransfer.getData('text/plain');

      // Prevent the default behavior
      evt.stop();

      // Insert plain text into the editor
      editor.model.change((writer) => {
        const insertPosition =
          editor.model.document.selection.getFirstPosition();
        insertPosition && writer.insertText(plainText, insertPosition);
      });
    });
  }
}
