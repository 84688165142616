import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {IAlert, IAlertPayload} from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  // Properties
  private baseURL = `${environment.apiUrl}performance-alert/`;

  constructor(private http: HttpClient) {}

  public getAlerts(params: any): Observable<{total: number; data: IAlert[]}> {
    return this.http.get<{total: number; data: IAlert[]}>(this.baseURL, {
      responseType: 'json',
      params: params,
    });
  }

  public createAlert(payload: IAlertPayload): Observable<IAlert> {
    return this.http.post<IAlert>(this.baseURL, payload, {
      responseType: 'json',
    });
  }

  public updateAlert(params: IAlert, alertId: string): Observable<IAlert> {
    return this.http.post<IAlert>(`${this.baseURL}${alertId}`, params, {
      responseType: 'json',
    });
  }

  public getAlert(alertId: string): Observable<IAlert> {
    return this.http.get<IAlert>(`${this.baseURL}${alertId}`);
  }

  public deleteAlert(alertId: string): Observable<object> {
    return this.http.delete(`${this.baseURL}${alertId}`);
  }

  public getAlertPreview(alertId: string, alert: IAlert): Observable<IAlert> {
    return this.http.post<IAlert>(`${this.baseURL}${alertId}/preview`, alert, {
      responseType: 'json',
    });
  }

  public generateAlertCsv(alertId: string): Observable<{fileId: string}> {
    return this.http.get<{fileId: string}>(
      `${this.baseURL}${alertId}/generate-csv`,
      {responseType: 'json'}
    );
  }

  public checkCsvStatus(alertId: string): Observable<{message: string}> {
    return this.http.get<{message: string}>(
      `${this.baseURL}${alertId}/check-csv-status`,
      {responseType: 'json'}
    );
  }

  public downloadAlert(alertId: string): Observable<Blob> {
    return this.http.get(`${this.baseURL}${alertId}/csv`, {
      responseType: 'blob',
    });
  }
}
