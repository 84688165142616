import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class SnackbarComponent {
  // Properties
  public htmlContent: SafeHtml;

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data,
    private sanitized: DomSanitizer
  ) {
    if (data.htmlMessage) {
      this.htmlContent = this.sanitized.bypassSecurityTrustHtml(
        data.data.message
      );
    }
  }
}
