import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

import {ConfirmDialogConfig} from '../../models/confirm.model';
import {DialogService} from 'src/app/core/services';
import {NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    NgClass,
    TranslateModule,
    SafeHtmlPipe,
  ],
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  // Properties
  subs: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig
  ) {}

  ngOnInit(): void {
    // Backdrop closing is disabled to manually close the dialog. This is to have control over the exit animation.
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((): void => {
          this.dialogService.closeDialog(this.dialogRef);
        })
      );
    }
  }

  onDismiss(): void {
    this.dialogService.closeDialog(this.dialogRef, false);
  }

  onConfirm(): void {
    this.dialogService.closeDialog(this.dialogRef, true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
