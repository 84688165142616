import {Injectable} from '@angular/core';
import {FontSize, TextAlignment} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  private fontSizes: Array<FontSize> = [
    {
      size: '12px',
      label: 'Small',
      translation: 'reporting.widget_editor.font_size.small',
    },
    {
      size: '16px',
      label: 'Normal (Recommended)',
      translation: 'reporting.widget_editor.font_size.normal',
    },
    {
      size: '20px',
      label: 'Large',
      translation: 'reporting.widget_editor.font_size.large',
    },
    {
      size: '24px',
      label: 'Huge',
      translation: 'reporting.widget_editor.font_size.huge',
    },
  ];

  private alignmentTypes: Array<TextAlignment> = [
    {
      label: 'Left',
      value: 'flex-start',
      translation: 'reporting.widget_editor.alignment.left',
    },
    {
      label: 'Center',
      value: 'center',
      translation: 'reporting.widget_editor.alignment.center',
    },
    {
      label: 'Right',
      value: 'flex-end',
      translation: 'reporting.widget_editor.alignment.right',
    },
  ];

  public get getFontSizes(): Array<FontSize> {
    return this.fontSizes;
  }

  public get getAlignmentTypes(): Array<TextAlignment> {
    return this.alignmentTypes;
  }

  setReportFontSize(fontSize: string | undefined): void {
    const root = document.documentElement;
    root.style.setProperty('--report-fontSize', fontSize || '');
  }
}
