import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

export const BREAKPOINT_INFIX = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
};

export interface IBreakpoint {
  name: string;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  // Properties
  private mobileWindowSize: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  mobileWindow$: Observable<string> = this.mobileWindowSize.asObservable();
  private isMobileSubject: Subject<boolean> = new Subject<boolean>();
  isMobile$: Observable<boolean> = this.isMobileSubject.asObservable();
  private scrollSubject: Subject<boolean> = new Subject<boolean>();
  scroll$: Observable<boolean> = this.scrollSubject.asObservable();
  private disabledScrollSubject: Subject<boolean> = new Subject<boolean>();
  disabledScroll$: Observable<boolean> =
    this.disabledScrollSubject.asObservable();

  private mobileBehavior$ = new BehaviorSubject<boolean>(false);
  private breakPointBehavior$ = new BehaviorSubject<IBreakpoint | null>(null);
  private heighWindowBehavior$ = new BehaviorSubject<number | null>(null);

  constructor() {
    //
  }

  public set setIsMobile(value: boolean) {
    this.isMobileSubject.next(value);
    this.mobileBehavior$.next(value);
  }

  public set setMobileWindowSize(value: string) {
    this.mobileWindowSize.next(value);
  }

  public set setScroll(value: boolean) {
    this.scrollSubject.next(value);
  }

  public set disabledScroll(value: boolean) {
    this.disabledScrollSubject.next(value);
  }

  public getMobileBehavior(): Observable<boolean> {
    return this.mobileBehavior$.asObservable();
  }

  public checkIfMobile(width?: number): boolean {
    const innerWidth = width ? width : window.innerWidth;
    return !!(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
      innerWidth <= 991.98
    );
  }

  public checkMobileWindowSize(windowWidth?: number): string {
    const innerWidth = windowWidth ? windowWidth : window.innerWidth;
    if (innerWidth <= 767.98) {
      return 'small';
    } else {
      return 'large';
    }
  }

  /**
   *
   * @param width
   */
  public setBreakpoint(width?: number): void {
    const innerWidth = width ? width : window.innerWidth;
    innerWidth < 576 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.xs,
        value: innerWidth,
      });
    innerWidth >= 577 &&
      innerWidth <= 768 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.sm,
        value: innerWidth,
      });
    innerWidth >= 769 &&
      innerWidth <= 992 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.md,
        value: innerWidth,
      });
    innerWidth >= 993 &&
      innerWidth <= 1200 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.lg,
        value: innerWidth,
      });
    innerWidth >= 1201 &&
      innerWidth <= 1400 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.xl,
        value: innerWidth,
      });
    innerWidth >= 1401 &&
      this.breakPointBehavior$.next({
        name: BREAKPOINT_INFIX.xxl,
        value: innerWidth,
      });
  }

  /**
   *
   * @returns Observable<IBreakpoint | null>
   *
   * interface IBreakpoint {
   * name: string;
   * value: number;
   * }
   */
  public getBreakpoint(): Observable<IBreakpoint | null> {
    return this.breakPointBehavior$.asObservable();
  }

  public setWindowHeigh(heigh: number): void {
    this.heighWindowBehavior$.next(heigh);
  }

  public getWindowHeigh(): Observable<number | null> {
    return this.heighWindowBehavior$.asObservable();
  }
}
