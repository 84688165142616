import {Injectable} from '@angular/core';

declare global {
  interface Window {
    amplitude: any;
  }
}

const amplitude = window.amplitude;

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor() {}

  public trackEvent(eventName: string): void {
    if (amplitude) {
      amplitude.track(eventName);
    }
  }
}
