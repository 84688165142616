import {Injectable} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ComponentType} from '@angular/cdk/portal';

import {MobileService} from './mobile.service';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  // Properties
  private isMobile: boolean;
  private largeMobile: boolean;
  private dialogAnimationClasses: string[] = [
    'animate__animated',
    'animate__slideInUp',
    'animate__faster',
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public loader: LoadingService,
    private mobileService: MobileService
  ) {
    this.mobileService
      .getMobileBehavior()
      .subscribe((res: boolean) => (this.isMobile = res));
    if (this.isMobile) {
      this.mobileService.mobileWindow$.subscribe((res: string): void => {
        this.largeMobile = res === 'large';
      });
    }
  }

  public openDialog(
    dialogComponent: ComponentType<any>,
    options?: MatDialogConfig,
    onCloseActions?: any
  ): void {
    if (options) {
      options.width =
        this.largeMobile && options.data.overrideWidth
          ? '70vw'
          : options?.width;
    }
    const dialogRef = this.dialog.open(
      dialogComponent,
      this.isMobile && !this.largeMobile
        ? {
            data: options?.data,
            disableClose: true,
            width: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            minHeight: options?.minHeight ? options?.minHeight : '',
            position: {bottom: '0px'},
            panelClass:
              options?.panelClass && Array.isArray(options?.panelClass)
                ? [...options.panelClass, ...this.dialogAnimationClasses]
                : this.dialogAnimationClasses,
          }
        : options
    );

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (onCloseActions) {
          onCloseActions(res ? res : null);
        }
        dialogSub.unsubscribe();
      },
    });
  }

  /**
   *
   * @param dialogRef
   * @param valueToEmit
   */
  public closeDialog<T>(dialogRef: MatDialogRef<T>, valueToEmit?: any): void {
    if (this.isMobile) {
      dialogRef.addPanelClass('animate__slideOutDown');
      setTimeout(() => dialogRef.close(valueToEmit), 500);
    } else {
      dialogRef.close(valueToEmit);
    }
  }
}
