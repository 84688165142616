import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  map,
  take,
} from 'rxjs';
import {
  AddPagePayload,
  AddUserAccesPayload,
  AllTemplatePayload,
  ClearReportCachePayload,
  CloneReportPayload,
  CreateReportFromTemplatePayload,
  CreateReportPayload,
  DataQueueResponse,
  FeaturedTemplatePayload,
  MoveReportPayload,
  PageTemplate,
  ReorderPagesPayload,
  Report,
  ReportPage,
  ReportTemplate,
  SavePageTemplatePayload,
  SaveTemplatePayload,
  SearchReportParams,
  ShareEmailPayload,
  TemplateCategory,
  TemplatePDFStatus,
  UpdateReportPayload,
  UpdateTemplatePayload,
  Widget,
  addTemplatePagePayload,
} from 'src/app/shared/models';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../../../environments/environment';
import {User} from 'src/app/shared/models/user.model';
import {ApiService} from './api.service';
import {WidgetService} from './widget.service';
import {
  HelperService,
  TableDataResponse,
  setCharacterEscape,
} from 'src/app/shared/helpers';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  reportInfo: {report: Report; originalPageState: ReportPage};
  private changeSubject = new Subject<{
    hasChanges: boolean;
    skipPageState?: boolean;
  }>();
  hasChanges$ = this.changeSubject.asObservable();
  private loadingSubject: Subject<boolean> = new Subject<boolean>();
  isReportLoading$: Observable<boolean> = this.loadingSubject.asObservable();
  private bottomSheetSubject: Subject<boolean> = new Subject<boolean>();
  isBottomSheetOpen$: Observable<boolean> =
    this.bottomSheetSubject.asObservable();

  private presentationMode$ = new BehaviorSubject<boolean>(false);
  public presentationMode = this.presentationMode$.asObservable();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private widgetService: WidgetService,
    private helperService: HelperService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  private baseURL = `${environment.apiUrl}reporting/`;

  public set setChangeValue(value: {
    hasChanges: boolean;
    skipPageState?: boolean;
    from?: string;
  }) {
    this.changeSubject.next(value);
  }

  public set setIsLoadingValue(value: boolean) {
    this.loadingSubject.next(value);
  }

  public set setBottomSheetOpen(value: boolean) {
    this.bottomSheetSubject.next(value);
  }

  public set setPresentationMode(value: boolean) {
    this.presentationMode$.next(value);
  }

  public createReport(payload: CreateReportPayload): Observable<Report> {
    return this.apiService.post(`${this.baseURL}`, payload);
  }

  public updateReport(
    payload: UpdateReportPayload,
    reportId: string
  ): Observable<Report> {
    return this.apiService.post(`${this.baseURL}${reportId}/edit/`, payload);
  }

  public getReport(id: string): Observable<Report> {
    return this.apiService.get(`${this.baseURL}${id}/`);
  }

  public reorderPages(
    id: string,
    payload: ReorderPagesPayload
  ): Observable<Report> {
    return this.apiService.post(`${this.baseURL}${id}/reorder-pages`, payload);
  }

  public getFullReport(id: string): Observable<Report> {
    return this.apiService.get(`${this.baseURL}${id}/get-full-report`);
  }

  public getCoverPage(id: string): Observable<Report> {
    return this.http.get<Report>(`${this.baseURL}${id}/get-full-report`, {
      params: {coverpage: true},
    });
  }

  public getReportStatus(id: string): Observable<string> {
    return this.http
      .get(`${this.baseURL}${id}/status`, {
        responseType: 'text',
      })
      .pipe(map((val: string) => val.replace(/"/g, '')));
  }

  public getReports(
    params?: SearchReportParams
  ): Observable<TableDataResponse<Report>> {
    return this.apiService.get(`${this.baseURL}`, params);
  }

  public deleteReport(id: string): Observable<unknown> {
    return this.apiService.delete(`${this.baseURL}${id}/`);
  }

  public revokeExternalAccess(
    reportId: string,
    payload: {userId: string}
  ): Observable<unknown> {
    return this.http.post<unknown>(
      `${this.baseURL}${reportId}/revoke-access`,
      payload
    );
  }

  public downloadReport(reportId: string): Observable<{pdfId: string}> {
    return this.apiService.get(`${this.baseURL}${reportId}/generate-pdf`);
  }

  public checkPdfStatus(reportId: string, pdfId: string): Observable<Blob> {
    return this.http.get(`${this.baseURL}${reportId}/check-pdf/${pdfId}`, {
      responseType: 'blob',
    });
  }

  public getScheduledReport(
    reportId: string,
    payload: {filepath: string}
  ): Observable<Blob> {
    return this.http.post(
      `${this.baseURL}${reportId}/get-report-file`,
      payload,
      {responseType: 'blob'}
    );
  }

  public addPage(payload: AddPagePayload): Observable<ReportPage> {
    return this.apiService.post(
      `${this.baseURL}${payload.reportId}/add-page/`,
      payload || {}
    );
  }

  public addPageTemplate(
    payload: addTemplatePagePayload
  ): Observable<ReportPage> {
    return this.apiService.post(
      `${this.baseURL}report-page-template/insert-page-template`,
      payload
    );
  }

  public updatePage(
    reportId: string,
    pageId: string,
    payload: ReportPage
  ): Observable<ReportPage> {
    return this.apiService.post(
      `${this.baseURL}${reportId}/pages/${pageId}/update`,
      payload
    );
  }

  public updatePageVisibility(
    reportId: string,
    pageId: string,
    visibility: boolean
  ): Observable<any> {
    return this.apiService.patch(
      `${this.baseURL}${reportId}/update-visibility/${pageId}/`,
      {visibility}
    );
  }

  public duplicatePage(
    reportId: string,
    pageId: string
  ): Observable<ReportPage> {
    return this.http.post<ReportPage>(
      `${this.baseURL}${reportId}/pages/${pageId}/clone-page`,
      {responseType: 'json'}
    );
  }

  public deletePage(reportId: string, pageId: string): Observable<Report> {
    return this.http.delete<Report>(
      `${this.baseURL}${reportId}/delete-report-page/${pageId}/`,
      {responseType: 'json'}
    );
  }

  public addUserAccess(
    payload: AddUserAccesPayload,
    reportId: string
  ): Observable<Report> {
    return this.apiService.post(`${this.baseURL}${reportId}/access`, payload);
  }

  public getUsersWithAccess(reportId: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseURL}${reportId}/access`, {
      responseType: 'json',
    });
  }

  public getReportPage(
    reportId: string,
    pageId: string
  ): Observable<ReportPage> {
    return this.apiService.get(
      `${this.baseURL}${reportId}/pages/${pageId}/get-page`
    );
  }

  public saveShareEmail(
    payload: ShareEmailPayload,
    reportId: string
  ): Observable<Report> {
    return this.http.post<Report>(
      `${this.baseURL}${reportId}/save-schedule-information/`,
      payload,
      {responseType: 'json'}
    );
  }

  public saveTemplate(
    payload: SaveTemplatePayload,
    reportId: string
  ): Observable<ReportTemplate> {
    return this.apiService.post(
      `${this.baseURL}${reportId}/save-as-template/`,
      payload
    );
  }

  public setFeaturedTemplate(
    templateId: string,
    payload: FeaturedTemplatePayload
  ): Observable<ReportTemplate> {
    return this.apiService.post(
      `${this.baseURL}templates/${templateId}`,
      payload
    );
  }

  // TODO: Ask if the template builder will be removed
  public getTemplate(id: string): Observable<ReportTemplate> {
    return this.http.get<ReportTemplate>(`${this.baseURL}templates/${id}/`, {
      responseType: 'json',
    });
  }

  // TODO: Ask if the template builder will be removed
  public getFullTemplate(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}template/${id}/get-full-report`);
  }

  public getAllTemplates(
    payload: AllTemplatePayload
  ): Observable<TableDataResponse<ReportTemplate>> {
    return this.apiService.post(`${this.baseURL}templates/get-all`, payload);
  }

  public getTemplatePDFStatus(id: string): Observable<{
    status: TemplatePDFStatus;
    pdf: string;
    lastUpdate: string;
  }> {
    return this.http.get<{
      status: TemplatePDFStatus;
      pdf: string;
      lastUpdate: string;
    }>(`${this.baseURL}templates/${id}/status-pdf`);
  }

  public getReportGoalTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseURL}templates/goal-types`);
  }

  public getTemplateCategories(): Observable<TemplateCategory> {
    return this.http.get<TemplateCategory>(
      `${this.baseURL}templates/categories`
    );
  }

  public getPageTemplateGlobalTypes(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.baseURL}report-page-template/goal-types`
    );
  }

  public getWidgetTemplateGlobalTypes(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.baseURL}widget-templates/goal-types`
    );
  }

  public getTemplatePage(
    templateId: string,
    pageId: string
  ): Observable<ReportPage> {
    return this.apiService.get(
      `${this.baseURL}templates/${templateId}/pages/${pageId}/get-page`
    );
  }

  public getDataQueue(reportId: string): Observable<DataQueueResponse> {
    return this.http.get<any>(`${this.baseURL}${reportId}/download-status`);
  }

  public createReportFromTemplate(
    payload: CreateReportFromTemplatePayload,
    templateId: string
  ): Observable<any> {
    return this.apiService.post(
      `${this.baseURL}templates/${templateId}/create-from-template/`,
      payload
    );
  }

  public cloneReport(
    payload: CloneReportPayload,
    templateId: string
  ): Observable<Report> {
    return this.apiService.post(
      `${this.baseURL}${templateId}/clone-reporting/`,
      payload
    );
  }

  public deleteTemplate(id: string): Observable<unknown> {
    return this.apiService.delete(`${this.baseURL}templates/${id}/`);
  }

  public savePageTemplate(
    reportId: string,
    pageId: string,
    payload: SavePageTemplatePayload
  ): Observable<ReportPage> {
    return this.apiService.post(
      `${this.baseURL}${reportId}/create-new-page-template/${pageId}/`,
      payload
    );
  }

  public updateTemplate(
    payload: UpdateTemplatePayload
  ): Observable<ReportTemplate> {
    return this.apiService.post(
      `${this.baseURL}templates/${payload.id}`,
      payload
    );
  }

  public getPageTemplate(pageId): Observable<PageTemplate> {
    return this.apiService.get(
      `${this.baseURL}report-page-template/get-page-template/${pageId}`
    );
  }

  public getAllPageTemplates(
    params?
  ): Observable<TableDataResponse<PageTemplate>> {
    return this.http.get<TableDataResponse<PageTemplate>>(
      `${this.baseURL}report-page-template/all-page-templates`,
      {
        responseType: 'json',
        params,
      }
    );
  }

  public deletePageTemplate(pageId: string): Observable<unknown> {
    return this.apiService.delete(
      `${this.baseURL}report-page-template/remove-page-template/${pageId}`
    );
  }

  public uploadPictureByPage(
    file: File,
    reportId: string,
    pageId: string
  ): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('pageId', pageId);

    return this.http.post(
      `${this.baseURL}${reportId}/upload-background`,
      formData
    );
  }

  public getPictureByPage(
    reportId: string,
    pageId: string,
    isTemplate?: boolean
  ): Observable<Blob> {
    const path = isTemplate
      ? `${this.baseURL}${reportId}/get-background-image/${pageId}/template`
      : `${this.baseURL}${reportId}/get-background-image/${pageId}`;
    return this.apiService.get(path, null, 'blob');
  }

  public refreshReportData(reportId: string): Observable<any> {
    return this.http.get(`${this.baseURL}${reportId}/updateData`, {
      responseType: 'json',
    });
  }

  public moveReport(payload: MoveReportPayload): Observable<any> {
    return this.apiService.post(`${this.baseURL}move-report/`, payload);
  }

  public clearReportCache(payload: ClearReportCachePayload): Observable<any> {
    return this.http.post(`${this.baseURL}clear-cache`, payload, {
      responseType: 'json',
    });
  }

  public getReportClient(reportId: string): Observable<any> {
    return this.apiService.get(`${this.baseURL}${reportId}/get-company-info/`);
  }

  public getReportWidget(
    reportId: string,
    widgetId: string
  ): Observable<Widget> {
    return this.apiService.get(
      `${this.baseURL}${reportId}/get-widget/${widgetId}/`
    );
  }

  public getOrgReports(search: string, orgId: string): Observable<any> {
    const params = {
      filter: `name contains ${setCharacterEscape(search)}`,
      limit: 6,
      page: 1,
    };
    return this.apiService.get(`${this.baseURL}${orgId}/get-reports/`, params);
  }

  public getShareableLink(reportId: string): Observable<{publicLink: string}> {
    return this.http.get<{publicLink: string}>(
      `${this.baseURL}${reportId}/get-shareable-link`
    );
  }

  public removeShareableLink(reportId: string): Observable<{status: string}> {
    return this.http.put<{status: string}>(
      `${this.baseURL}${reportId}/remove-share-link`,
      {
        reportId,
      }
    );
  }

  public warnOfUnsavedChanges(
    action?: () => void,
    navigationUrl?: string,
    discardAction?: () => void,
    from?: string
  ): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(
            'reporting.report_builder.unsaved_changes_label'
          ),
          content: this.translate.instant(
            'reporting.report_builder.unsaved_notification'
          ),
          button: this.translate.instant(
            'reporting.report_builder.discard_button_label'
          ),
          confirmButton: this.translate.instant(
            'reporting.report_builder.confirm_button_label'
          ),
          isDelete: true,
          showIcon: false,
        },
      });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res === true) {
          this.widgetService.setSaveValue = true;
          const finishSaveSub: Subscription =
            this.widgetService.saveFinished$.subscribe((res) => {
              if (res) {
                // Do action after saving
                if (navigationUrl) {
                  this.router.navigate([navigationUrl]).then();
                } else {
                  if (!action) {
                    return;
                  }
                  action();
                }
                finishSaveSub.unsubscribe();
              }
            });
        }

        if (res === false) {
          // Return page to original state
          if (discardAction) {
            discardAction();
          }
          // Do action after discarding changes
          this.setChangeValue = {hasChanges: false};
          if (navigationUrl) {
            this.router.navigate([navigationUrl]).then();
          } else {
            if (!action) {
              return;
            }
            action();
          }
        }

        dialogSub.unsubscribe();
      });
  }

  returnToOriginalPageState(
    reportPages: ReportPage[],
    selectedPage: ReportPage,
    originalPageState: ReportPage
  ): void {
    const reportPage: ReportPage = this.helperService
      ? (this.helperService.copyObject(originalPageState) as ReportPage)
      : (JSON.parse(JSON.stringify(originalPageState)) as ReportPage);
    reportPages[selectedPage.order] = reportPage;
  }
}
