import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Connection, ConnectionAccount, Service} from 'src/app/shared/models';
import {TableDataResponse, setCharacterEscape} from 'src/app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  // Authentication types
  public static readonly AUTH_TYPE_OAUTH2 = 'OATH2';
  public static readonly AUTH_TYPE_API_TOKEN = 'API_TOKEN';

  constructor(private http: HttpClient) {}

  baseUrl = '/api/v2/services/';

  public getConnectionsServices(): Observable<Connection[]> {
    return this.http.get<Connection[]>(`/api/v2/connections/with-services`, {
      responseType: 'json',
    });
  }

  public getAccounts(params): Observable<TableDataResponse<ConnectionAccount>> {
    return this.http.get<TableDataResponse<ConnectionAccount>>(
      `${this.baseUrl}items`,
      {
        responseType: 'json',
        params,
      }
    );
  }

  public getServiceList(
    userId: string,
    serviceType: string | null = null
  ): Observable<Service[]> {
    return this.http.get<Service[]>(`${this.baseUrl}`, {
      params: serviceType
        ? {filter: `owners contains ${setCharacterEscape(userId)}`, serviceType}
        : {filter: `owners contains ${setCharacterEscape(userId)}`},
      responseType: 'json',
    });
  }

  public refreshAccounts(
    serviceId: number
  ): Observable<{[key: string]: string}> {
    return this.http.get<{[key: string]: string}>(
      `${this.baseUrl}${serviceId}/refresh/`,
      {responseType: 'json'}
    );
  }

  public updateService(service: Service): Observable<Service> {
    return this.http.post<Service>(`${this.baseUrl}${service._id}/`, service, {
      responseType: 'json',
    });
  }

  public deleteService(serviceId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}${serviceId}`, {
      responseType: 'json',
    });
  }
}
