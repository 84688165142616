import {Injectable} from '@angular/core';
import {RGBColor} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor() {}

  /* //How to Use

Setup
-------

let color1 = "rgb(20,60,200)";
let color2 = "rgba(20,60,200,0.67423)";
let color3 = "#67DAF0";
let color4 = "#5567DAF0";
let color5 = "#F3A";
let color6 = "#F3A9";
let color7 = "rgb(200,60,20)";
let color8 = "rgba(200,60,20,0.98631)";

Tests
-------

<-----Log Blending----->
Shade (Lighten or Darken)
pSBC ( 0.42, color1 ); // rgb(20,60,200) + [42% Lighter] => rgb(166,171,225)
pSBC ( -0.4, color5 ); // #F3A + [40% Darker] => #c62884
pSBC ( 0.42, color8 ); // rgba(200,60,20,0.98631) + [42% Lighter] => rgba(225,171,166,0.98631)

Shade with Conversion (use "c" as your "to" color)
-----------------------------------------------------
pSBC ( 0.42, color2, "c" ); // rgba(20,60,200,0.67423) + [42% Lighter] + [Convert] => #a6abe1ac

RGB2Hex & Hex2RGB Conversion Only (set percentage to zero)
------------------------------------------------------------
pSBC ( 0, color6, "c" ); // #F3A9 + [Convert] => rgba(255,51,170,0.6)

<-----Blending----->
pSBC ( -0.5, color2, color8 ); // rgba(20,60,200,0.67423) + rgba(200,60,20,0.98631) + [50% Blend] => rgba(142,60,142,0.83)
pSBC ( 0.7, color2, color7 ); // rgba(20,60,200,0.67423) + rgb(200,60,20) + [70% Blend] => rgba(168,60,111,0.67423)
pSBC ( 0.25, color3, color7 ); // #67DAF0 + rgb(200,60,20) + [25% Blend] => rgb(134,191,208)
pSBC ( 0.75, color7, color3 ); // rgb(200,60,20) + #67DAF0 + [75% Blend] => #86bfd0

<-----Linear Blending----->
Shade (Lighten or Darken)
--------------------------
pSBC ( 0.42, color1, false, true ); // rgb(20,60,200) + [42% Lighter] => rgb(119,142,223)
pSBC ( -0.4, color5, false, true ); // #F3A + [40% Darker] => #991f66
pSBC ( 0.42, color8, false, true ); // rgba(200,60,20,0.98631) + [42% Lighter] => rgba(223,142,119,0.98631)

Shade with Conversion (use "c" as your "to" color)
---------------------------------------------------
pSBC ( 0.42, color2, "c", true ); // rgba(20,60,200,0.67423) + [42% Lighter] + [Convert] => #778edfac

RGB2Hex & Hex2RGB Conversion Only (set percentage to zero)
-----------------------------------------------------------
pSBC ( 0, color6, "c", true ); // #F3A9 + [Convert] => rgba(255,51,170,0.6)

<-----Blending----->
pSBC ( -0.5, color2, color8, true ); // rgba(20,60,200,0.67423) + rgba(200,60,20,0.98631) + [50% Blend] => rgba(110,60,110,0.83)
pSBC ( 0.7, color2, color7, true ); // rgba(20,60,200,0.67423) + rgb(200,60,20) + [70% Blend] => rgba(146,60,74,0.67423)
pSBC ( 0.25, color3, color7, true ); // #67DAF0 + rgb(200,60,20) + [25% Blend] => rgb(127,179,185)
pSBC ( 0.75, color7, color3, true ); // rgb(200,60,20) + #67DAF0 + [75% Blend] => #7fb3b9

<-----Other Stuff----->
Error Checking
------------------
pSBC ( 0.42, "#FFBAA" ); // #FFBAA + [42% Lighter] => null(Invalid Input Color)
pSBC ( 42, color1, color5 ); // rgb(20,60,200) + #F3A + [4200% Blend] => null(Invalid Percentage Range)
pSBC ( 0.42, {} ); // [object Object] + [42% Lighter] => null(Strings Only for Color)
pSBC ( "42", color1 ); // rgb(20,60,200) + ["42"] => null(Numbers Only for Percentage)
pSBC ( 0.42, "salt" ); // salt + [42% Lighter] => null(A Little Salt is No Good...)

Error Check Fails(Some Errors are not Caught)
------------------------------------------------
pSBC ( 0.42, "#salt" ); // #salt + [42% Lighter] => #a5a5a500(...and a Pound of Salt is Jibberish)

Ripping
---------
pSBCr ( color4 ); // #5567DAF0 + [Rip] => [object Object] => {'r':85,'g':103,'b':218,'a':0.941} */

  pSBCr;
  pSBC = (p: any, c0: any, c1?: any, l?: any): any => {
    let r, g, b, P, f, t, h;
    const i = parseInt,
      m = Math.round;
    let a: any = typeof c1 == 'string';
    if (
      typeof p != 'number' ||
      p < -1 ||
      p > 1 ||
      typeof c0 != 'string' ||
      (c0[0] != 'r' && c0[0] != '#') ||
      (c1 && !a)
    ) {
      return null;
    }
    if (!this.pSBCr) {
      this.pSBCr = (d: any): any => {
        let n = d.length;
        const x: any = {};
        if (n > 9) {
          ([r, g, b, a] = d = d.split(',')), (n = d.length);
          if (n < 3 || n > 4) {
            return null;
          }
          (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
            (x.g = i(g)),
            (x.b = i(b)),
            (x.a = a ? parseFloat(a) : -1);
        } else {
          if (n == 8 || n == 6 || n < 4) {
            return null;
          }
          if (n < 6) {
            d =
              '#' +
              d[1] +
              d[1] +
              d[2] +
              d[2] +
              d[3] +
              d[3] +
              (n > 4 ? d[4] + d[4] : '');
          }
          d = i(d.slice(1), 16);
          if (n == 9 || n == 5) {
            (x.r = (d >> 24) & 255),
              (x.g = (d >> 16) & 255),
              (x.b = (d >> 8) & 255),
              (x.a = m((d & 255) / 0.255) / 1000);
          } else {
            (x.r = d >> 16),
              (x.g = (d >> 8) & 255),
              (x.b = d & 255),
              (x.a = -1);
          }
        }
        return x;
      };
    }
    (h = c0.length > 9),
      (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
      (f = this.pSBCr(c0)),
      (P = p < 0),
      (t =
        c1 && c1 != 'c'
          ? this.pSBCr(c1)
          : P
            ? {r: 0, g: 0, b: 0, a: -1}
            : {r: 255, g: 255, b: 255, a: -1}),
      (p = P ? p * -1 : p),
      (P = 1 - p);
    if (!f || !t) {
      return null;
    }
    if (l) {
      (r = m(P * f.r + p * t.r)),
        (g = m(P * f.g + p * t.g)),
        (b = m(P * f.b + p * t.b));
    } else {
      (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
        (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
        (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
    }
    (a = f.a),
      (t = t.a),
      (f = a >= 0 || t >= 0),
      (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
    if (h) {
      return (
        'rgb' +
        (f ? 'a(' : '(') +
        r +
        ',' +
        g +
        ',' +
        b +
        (f ? ',' + m(a * 1000) / 1000 : '') +
        ')'
      );
    } else {
      return (
        '#' +
        (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
          .toString(16)
          .slice(1, f ? undefined : -2)
      );
    }
  };

  isDark(color: string): boolean | string {
    if (!color) {
      return 'Error: No color provided.';
    }
    const colorFormat = this.getColorType(color);

    if (!colorFormat) {
      return 'Color format not supported.';
    }

    let r;
    let g;
    let b;

    let hexInRgb = {r: 0, g: 0, b: 0};
    let rgbArray = color.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/);
    let hslInRgb = {r: 0, g: 0, b: 0};
    switch (colorFormat) {
      case 'hex':
        hexInRgb = this.hexToRgb(color) as {r: number; g: number; b: number};
        r = hexInRgb?.r;
        g = hexInRgb?.g;
        b = hexInRgb?.b;
        break;
      case 'rgb':
        rgbArray = color.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/);
        if (rgbArray) {
          r = parseInt(rgbArray[1]);
          g = parseInt(rgbArray[2]);
          b = parseInt(rgbArray[3]);
        }
        break;
      case 'hsl':
        hslInRgb = this.hslToRgb(color);
        r = hslInRgb?.r;
        g = hslInRgb?.g;
        b = hslInRgb?.b;
        break;
    }

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 128;
  }

  getColorType(color): string | null {
    if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(color)) {
      return 'hex';
    } else if (/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/.test(color)) {
      return 'rgb';
    } else if (/^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/.test(color)) {
      return 'hsl';
    } else if (
      /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/.test(
        color
      )
    ) {
      return 'rgba';
    }
    // else if (/^hsla\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*(\d*(?:\.\d+)?)\)$/.test(color)) {
    //   return 'hsla';
    // }
    else {
      return null;
    }
  }

  hexToRgb(hex): RGBColor | null {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  hslToRgb(color): {r: number; g: number; b: number} {
    const hsl = color.match(/^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/);
    const h = hsl[1] / 360;
    const s = hsl[2] / 100;
    const l = hsl[3] / 100;
    let r, g, b;
    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t): number => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
      g = Math.round(hue2rgb(p, q, h) * 255);
      b = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);
    }
    return {r, g, b};
  }

  /**
   * 
   * @param rgba 
   * the funciotn support the following
    rgba(255,25,2,0.5),
    rgba(255 25 2 / 0.5),
    rgba(50%,30%,10%,0.5),
    rgba(50%,30%,10%,50%),
    rgba(50% 30% 10% / 0.5),
    rgba(50% 30% 10% / 50%),
   * @returns 
   */
  rgbaToHex(rgba: string): string {
    // extract the separator
    const sep = rgba.indexOf(',') > -1 ? ',' : ' ';

    // get the numeric values array from the rgba string, without separator.
    const rgbaArray = rgba.substring(5).split(')')[0].split(sep);

    const rgbaArrayNumber: Array<number> = new Array(4);
    const hexColorArray: Array<string> = new Array(4);

    // Strip the slash if using space-separated syntax
    if (rgbaArray.indexOf('/') > -1) {
      rgbaArray.splice(3, 1);
    }

    for (const R in rgbaArray) {
      const r = rgbaArray[R];
      if (r.indexOf('%') > -1) {
        const p = parseInt(r.substring(0, r.length - 1)) / 100;

        const index = Number(R);
        if (index < 3) {
          rgbaArrayNumber[index] = Math.round(p * 255);
        } else {
          rgbaArrayNumber[index] = p;
        }
      }
    }

    rgbaArrayNumber.forEach((color, i) => {
      //the rgb colors
      if (i !== 3) {
        hexColorArray[i] =
          color < 10 ? `0${color.toString(16)}` : color.toString(16);
      }

      // the alpha (opacity level)
      if (i === 3) {
        const alpha = Math.round(color * 255).toString(16);
        hexColorArray[i] = color < 10 ? `0${alpha}` : alpha;
      }
    });

    return hexColorArray.join('');
  }
}
