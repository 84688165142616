import {PlanType} from '../enums';

interface AbilityConfig {
  [ability: string]: PlanType[];
}

const abilityConfig: AbilityConfig = {
  /* Feature Validations */
  createTheme: [
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
    PlanType.Free,
  ],
  saveTheme: [
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
    PlanType.Free,
  ],
  switchTo: [
    PlanType.Free,
    PlanType.Basic,
    PlanType.Freelance,
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
  ],
  saveBrandingData: [
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
    PlanType.Free,
  ],
  saveSSOSettings: [
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
    PlanType.Free,
  ],
  viewBasicConnections: [PlanType.Individual, PlanType.InHouse],
  viewAdvancedConnections: [
    PlanType.Free,
    PlanType.Basic,
    PlanType.Freelance,
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
  ],
  shareWith: [
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
    PlanType.Free,
  ],
  setBasicStarted: [PlanType.Individual, PlanType.InHouse],
  setAdvancedStarted: [
    PlanType.Free,
    PlanType.Basic,
    PlanType.Freelance,
    PlanType.Pro,
    PlanType.Premium,
    PlanType.Agency,
    PlanType.Enterprise,
  ],
  /* General Validations */
  isIndividual: [PlanType.Individual, PlanType.InHouse],
  isBasic: [PlanType.Basic, PlanType.Freelance],
  setNavigation: [
    PlanType.Individual,
    PlanType.Basic,
    PlanType.InHouse,
    PlanType.Freelance,
  ],
};

export function canUser(ability: string, userPlan: PlanType): boolean {
  const allowedPlans: PlanType[] = abilityConfig[ability];
  return allowedPlans ? allowedPlans.includes(userPlan) : false;
}
