import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export type backgroundImageType =
  | 'reportThemeBackground'
  | 'customThemeBackgroundImage'
  | 'page';
export interface ReportingState {
  pages: Page[];
  currentBackgroundImageName: string;
}

interface Page {
  reportId: string;
  pageId: string;
  backgroundImage: any;
  backgroundImageType: backgroundImageType;
}

@Injectable({providedIn: 'root'})
export class ReportingStore {
  private reportingState$ = new BehaviorSubject<ReportingState | null>(null);

  constructor() {
    //
  }

  setState(payload: ReportingState | null): void {
    return this.reportingState$.next(payload);
  }

  getState(): Observable<any> {
    return this.reportingState$.asObservable();
  }

  clearState(): void {
    this.setState(null);
  }
  unsubscribe(): void {
    this.reportingState$.unsubscribe();
  }

  updatePages(page: Page): void {
    const state = this.reportingState$.getValue();
    if (state) {
      const exist = state.pages.find(
        (p) => p.reportId === page.reportId && p.pageId === page.pageId
      );

      if (
        exist !== undefined &&
        state.pages.length > 0 &&
        page.backgroundImageType === 'customThemeBackgroundImage'
      ) {
        state.pages.forEach((p, idx) => {
          if (
            p.backgroundImageType === 'reportThemeBackground' ||
            p.backgroundImageType === 'customThemeBackgroundImage'
          ) {
            p.backgroundImage = page.backgroundImage;
            state.pages[idx] = p;
          }
        });
        this.setState(state);
      }

      if (exist) {
        const toUpdate = exist;
        const index = state.pages.findIndex(
          (p) => p.reportId === page.reportId && p.pageId === page.pageId
        );

        toUpdate.backgroundImage = page.backgroundImage;
        toUpdate.backgroundImageType = page.backgroundImageType;
        state.pages[index] = toUpdate;
        this.setState(state);
      } else {
        state.pages = [...state.pages, page];
        this.setState(state);
      }
    } else {
      const newState = {pages: [page], currentBackgroundImageName: ''};
      this.setState(newState);
    }
  }

  deletePage(page: Page): void {
    const state = this.reportingState$.getValue();
    if (state) {
      if (state.pages) {
        const newPages = state.pages.filter(
          (p) => p.reportId !== page.reportId && p.pageId !== page.pageId
        );
        if (newPages) {
          state.pages = [...newPages];
          this.setState(state);
        }
      }
    }
  }

  deleteTypeNoPage(reportId: string, currentImageName?: string): void {
    const state = this.reportingState$.getValue();
    if (state) {
      if (state.pages) {
        const newPages = state.pages.filter(
          (p) => p.reportId === reportId && p.backgroundImageType === 'page'
        );
        this.setState({
          pages: newPages,
          currentBackgroundImageName: currentImageName ?? '',
        });
      }
    }
  }

  handleCustomBackgroundPage(reportId, imageFile): void {
    const state = this.reportingState$.getValue();
    if (state) {
      if (state.pages.length > 0) {
        state.pages.forEach((p, idx) => {
          if (p.reportId === reportId && p.backgroundImageType !== 'page') {
            p.backgroundImage = imageFile;
            p.backgroundImageType = 'customThemeBackgroundImage';
            state.pages[idx] = p;
          }
        });
        this.setState(state);
      }
    }
  }

  updateCurrentBackgroundImageName(name?: string | null): void {
    const pages = this.reportingState$?.getValue()?.pages;
    this.setState({
      pages: pages ?? ([] as Page[]),
      currentBackgroundImageName: name ?? '',
    });
  }
}
