import {Pipe, PipeTransform} from '@angular/core';

export const formatNumberCompactFunction = (value: number): string => {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
  });
  return formatter.format(value);
};
@Pipe({
  name: 'compactNumber',
  standalone: true,
})
export class CompactNumberPipe implements PipeTransform {
  transform(value: number): string {
    return formatNumberCompactFunction(value);
  }
}
