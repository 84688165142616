import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable, EMPTY} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {map, catchError, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ServiceAuthService  {
  private apiUrl = '/api/v2/auth/';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<void> {
    return this.validateSignIn(route);
  }

  openSignInDialog(
    provider: string,
    clientId?: string
  ): Observable<{redirect: string}> {
    const referer = window.location.href.replace('?sign-up=true', '');
    const params: {[param: string]: string} = {
      referer: clientId ? `${referer}?clientId=${clientId}` : referer,
    };
    const url = `${this.apiUrl}token-send/${provider}`;
    return this.http
      .get<{redirect: string}>(url, {
        responseType: 'json',
        withCredentials: true,
        params,
      })
      .pipe(
        tap(({redirect}) => {
          window.location.href = decodeURIComponent(redirect);
        })
      );
  }

  serviceLogin(provider, payload): Observable<{redirect: string}> {
    return this.http.post<{redirect: string}>(
      `${this.apiUrl}create/${provider}/`,
      payload,
      {responseType: 'json'}
    );
  }

  private validateSignIn(route: ActivatedRouteSnapshot): Observable<void> {
    const url = `${this.apiUrl}token/${route.paramMap.get('provider')}`;
    const params: {[param: string]: string} = {
      uri: window.location.href,
    };
    ['error', 'code', 'scope', 'state'].forEach((param) => {
      const value = route.queryParamMap.get(param);
      if (value) {
        params[param] = value;
      }
    });
    return this.http
      .get<{redirect: string}>(url, {responseType: 'json', params})
      .pipe(
        map(({redirect}) => {
          this.router.navigate([redirect]);
        }),
        catchError((error, caught) => {
          this.snackBar.open(
            error.error ? error.error.message : 'Authentication error',
            '',
            {panelClass: 'alertSnack', duration: 3000}
          );
          this.router.navigate(['']);
          return EMPTY;
        })
      );
  }
}
