import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {
  Organization,
  User,
  ServiceAccount,
  IOrganizationList,
  CustomError,
} from 'src/app/shared/models';
import {handleError} from '../errors';
import {environment} from 'src/environments/environment';
import {setCharacterEscape} from 'src/app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  private readonly apiURL = environment.apiUrl;

  public getServiceAccounts(params): Observable<ServiceAccount[]> {
    return this.httpClient.get<ServiceAccount[]>(
      `${this.apiURL}services/get-accounts`,
      {responseType: 'json', params}
    );
  }

  public getServiceAccountsTypeAhead(query: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}services/get-accounts`, {
      params: {'filter[]': `name contains ${setCharacterEscape(query)}`},
      responseType: 'json',
    });
  }

  public getUsersTypeAhead(query: string, inOrg?): Observable<User[]> {
    return this.httpClient.get<any[]>(`${this.apiURL}users/`, {
      params: {
        filter: `name contains ${query},roles notIn ROLE_EXTERNAL`,
        showAll: 'false',
        inOrg: inOrg ? inOrg.toString() : 'false',
      },
      responseType: 'json',
    });
  }

  public getExternalUsersTypeAhead(
    query: string,
    showAll = false
  ): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiURL}users/`, {
      params: {
        filter: `name contains ${query},roles in ROLE_EXTERNAL`,
        showAll: showAll.toString(),
        inOrg: 'true',
      },
      responseType: 'json',
    });
  }

  public updateUserDetails(userId: string, payload: object): Observable<User> {
    return this.httpClient.post<User>(
      `${this.apiURL}users/` + userId + '/',
      payload,
      {responseType: 'json'}
    );
  }

  public createUser(payload: object): Observable<User> {
    return this.httpClient.post<User>(`${this.apiURL}users/`, payload, {
      responseType: 'json',
    });
  }

  public removeUserFromOrganization(userId: string): Observable<User> {
    return this.httpClient.post<User>(
      `${this.apiURL}users/${userId}/remove-from-org/`,
      null,
      {responseType: 'json'}
    );
  }

  public uploadPicture(
    file: File,
    type: string,
    id?: string,
    userId?: string,
    pageId?: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);

    if (id) {
      formData.append('id', id);
    }

    if (userId) {
      formData.append('userId', userId);
    }
    if (pageId) {
      formData.append('pageId', pageId);
    }

    return this.httpClient.post(`${this.apiURL}file/upload`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getImage(
    name: string,
    type: string,
    id?: string,
    userId?: string
  ): Observable<Blob> {
    return this.httpClient.get(`${this.apiURL}file/get/`, {
      params: {
        image: name,
        type,
        id: id ?? false,
        userId: userId ?? false,
      },
      responseType: 'blob',
    });
  }

  getFile(name: string, type: string): Observable<any> {
    return this.httpClient.get(`${this.apiURL}file/get/`, {
      params: {
        file: name,
        type,
      },
      responseType: 'blob',
    });
  }

  deleteFile(
    name: string,
    type: string,
    id?: string,
    userId?: string
  ): Observable<object> {
    return this.httpClient.delete(`${this.apiURL}file/delete/`, {
      params: {
        image: name,
        type,
        id: id ?? false,
        userId: userId ?? false,
      },
      responseType: 'blob',
    });
  }

  public getOrgDetails(id: string): Observable<Organization> {
    return this.httpClient.get<Organization>(
      `${this.apiURL}organizations/` + id + '/',
      {responseType: 'json'}
    );
  }

  public updateOrgDetails(
    id: string,
    payload: object
  ): Observable<Organization> {
    return this.httpClient.post<Organization>(
      `${this.apiURL}organizations/` + id + '/',
      payload,
      {responseType: 'json'}
    );
  }

  public updateBrandingDetails(
    id: string,
    payload: FormData
  ): Observable<Organization> {
    return this.httpClient.post<Organization>(
      `${this.apiURL}update-branding/` + id + '/',
      payload,
      {responseType: 'json'}
    );
  }

  public getDefaultFavicon(): Observable<Blob> {
    const headers = new HttpHeaders().set('skip', 'true');
    return this.httpClient.get('./assets/img/svg/logo.svg', {
      headers,
      responseType: 'blob',
    });
  }

  /* Impersonate */
  public getOrgList(params: HttpParams): Observable<IOrganizationList> {
    return this.httpClient.get<IOrganizationList>(
      `${this.apiURL}impersonate/get-impersonate-list/`,
      {responseType: 'json', params}
    );
  }

  public orgImpersonate(payload): Observable<any> {
    return this.httpClient.post<any>(`${this.apiURL}impersonate/`, payload, {
      responseType: 'json',
    });
  }

  /* Common Requests */
  public get(path: string, params?: any, resType?: string): Observable<any> {
    const httpOptions: object = {
      params,
      responseType: resType ? resType : 'json',
    };

    return this.httpClient
      .get<any>(path, httpOptions)
      .pipe(catchError(handleError.bind(this)));
  }

  public post(path: string, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(path, payload, {responseType: 'json'})
      .pipe(catchError(handleError.bind(this)));
  }

  public patch(path: string, payload: any): Observable<any> {
    return this.httpClient
      .patch(path, payload, {responseType: 'json'})
      .pipe(catchError(handleError.bind(this)));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete<any>(path, {responseType: 'json'})
      .pipe(catchError(handleError.bind(this)));
  }
}
