import {Response} from '../../shared/models';
import {Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {IPossibleValueParams} from '../../shared/models';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PossibleValuesService {
  // Services
  private http: HttpClient = inject(HttpClient);
  private translate = inject(TranslateService);

  // Properties
  private possibleValueUrl = `${environment.apiUrl}possible-values/`;

  public getPossiblesValuesByName(
    payload: IPossibleValueParams
  ): Observable<Response> {
    const headers = new HttpHeaders()
      .set('skip', 'true')
      .append('language', this.translate.currentLang ?? 'en');

    return this.http.post<Response>(
      `${environment.fullApiUrl}${this.possibleValueUrl}get-filter-values-by-name/`,
      payload,
      {headers, responseType: 'json'}
    );
  }
}
