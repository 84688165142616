import {Pipe, PipeTransform} from '@angular/core';
import {Widget} from '../models';

export function hasWidgetSortBy(widget: Widget): boolean {
  const validTypes: Array<string> = [
    'mscolumn2d',
    'msline',
    'pie2d',
    'msbar2d',
    'mscombidy2d',
    'multiaxisline',
    'msarea',
    'doughnut2d',
    'stackedcolumn2d',
    'stackedbar2d',
    'gallery',
    'biggestchanges',
    'table',
    'timeline',
    'ranking',
    'geoHeatMap',
  ];

  return (
    validTypes.includes(widget?.chartType as string) ||
    validTypes.includes(widget?.widgetType as string)
  );
}
@Pipe({
  name: 'showSortBy',
  standalone: true,
})
export class ShowSortByPipe implements PipeTransform {
  transform(widget: Widget): boolean {
    return hasWidgetSortBy(widget);
  }
}
