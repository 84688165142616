import {Injectable} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import {SnackbarData} from '../../shared/models';
import {SnackbarComponent} from 'src/app/shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  success(
    message: string,
    duration?: number,
    action?: string,
    panelClass?: string,
    htmlMessage?: boolean
  ): MatSnackBarRef<SnackbarComponent> {
    return this.openSnackBar(
      message,
      'success',
      duration,
      panelClass,
      action,
      htmlMessage
    );
  }

  error(
    message: string,
    duration?: number,
    action?: string,
    panelClass?: string,
    htmlMessage?: boolean
  ): MatSnackBarRef<SnackbarComponent> {
    return this.openSnackBar(
      message,
      'error',
      duration,
      panelClass,
      action,
      htmlMessage
    );
  }

  warning(
    message: string,
    duration?: number,
    action?: string,
    panelClass?: string,
    htmlMessage?: boolean
  ): MatSnackBarRef<SnackbarComponent> {
    return this.openSnackBar(
      message,
      'warning',
      duration,
      panelClass,
      action,
      htmlMessage
    );
  }

  info(
    message: string,
    duration?: number,
    action?: string,
    panelClass?: string,
    htmlMessage?: boolean
  ): MatSnackBarRef<SnackbarComponent> {
    return this.openSnackBar(
      message,
      'information',
      duration,
      panelClass,
      action,
      htmlMessage
    );
  }

  private openSnackBar(
    message: string,
    type: string,
    duration?: number,
    panelClass?: string,
    action?: string,
    htmlMessage?: boolean
  ): MatSnackBarRef<SnackbarComponent> {
    const data: SnackbarData = {
      message,
      type,
      action,
    };

    const snackbarConfig: MatSnackBarConfig = {
      data: {
        data,
        htmlMessage,
      },
      panelClass: panelClass || '',
      duration: duration,
    };

    if (action) {
      delete snackbarConfig.duration;
    }

    return this.snackBar.openFromComponent(SnackbarComponent, snackbarConfig);
  }
}
