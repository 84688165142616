import {SupportTicket, SupportTicketRequest} from '../../shared/models';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private apiURL = `${environment.apiUrl}ticket`;

  constructor(private httpClient: HttpClient) {}

  createSupportTicket(ticket: SupportTicketRequest): Observable<SupportTicket> {
    const formData: FormData = new FormData();
    formData.append('attachment', ticket.attachment);
    formData.append('subject', ticket.subject);
    formData.append('description', ticket.description);
    formData.append('category', ticket.category);

    return this.httpClient.post<SupportTicket>(`${this.apiURL}/`, formData);
  }
}
