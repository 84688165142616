import {updateState, withDevtools, withStorageSync} from '@angular-architects/ngrx-toolkit';
import {computed, Signal} from '@angular/core';
import {signalStore, type, withComputed, withMethods} from '@ngrx/signals';
import {
  addEntity,
  entityConfig,
  updateAllEntities,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';

type ReportPresetColorsEntityType = {
  reportId: string;
  colors: string[];
  isActive?: boolean;
};

const ReportPresetColorsListEntityConfig = entityConfig({
  entity: type<ReportPresetColorsEntityType>(),
  collection: '_reportPresetColors',
  selectId: (preset) => preset.reportId,
});

export const ReportPresetColorsStore = signalStore(
  {providedIn: 'root'},
  withDevtools('ReportPresetColorsStore'),
  withEntities(ReportPresetColorsListEntityConfig),
  withStorageSync('reportPresetColors'),
  withComputed(({_reportPresetColorsEntities}) => ({
    getActivePresetColors: computed(() => {
      return _reportPresetColorsEntities().find((preset) => preset.isActive);
    }),
  })),
  withMethods((store) => ({
    addReportPresetColors(reportId: string, colors?: string[]): void {
      _addReportPresetColors(store, reportId, colors);
    },
    updateActiveReport(reportId: string): void {
      _updateActiveReport(store, reportId);
    },
    updateActiveReportPresetColors(reportId: string, colors: string[]): void {
      _updateActiveReportPresetColors(store, reportId, colors);
    },
  }))
);

function _addReportPresetColors(
  store,
  reportId: string,
  colors?: string[]
): void {
  const newPreset = {reportId, colors: colors || []};
  updateState(
    store,
    '[ReportPresetColors] Add Colors',
    addEntity(newPreset, ReportPresetColorsListEntityConfig)
  );
}

function _updateActiveReport(store, reportId: string): void {
  updateState(
    store,
    '[ReportPresetColors] Update Active Report',
    updateAllEntities(
      (preset) => ({
        isActive: preset.reportId === reportId,
      }),
      ReportPresetColorsListEntityConfig
    )
  );
}

function _updateActiveReportPresetColors(
  store,
  reportId: string,
  colors: string[]
): void {
  updateState(
    store,
    '[ReportPresetColors] Update Active Report Presets Colors',
    updateEntity(
      {id: reportId, changes: {colors}},
      ReportPresetColorsListEntityConfig
    )
  );
}
