import {
  signalStore,
  withComputed,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import {BillingPlan} from '../models';
import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed} from '@angular/core';

type BillingPlanStateSignal = {
  plan: BillingPlan | null;
};

const billingPlanInitialState: BillingPlanStateSignal = {plan: null};

export const BillingPlanStore = signalStore(
  {providedIn: 'root'},
  withDevtools('BillingPlanStore'),
  // billing state
  withState(billingPlanInitialState),

  // billing store methods/computed/hooks
  withComputed(({plan}) => ({
    getPlan: computed(() => {
      return plan();
    }),
  })),
  withMethods((store) => ({
    updateBillingPlanMethod: (plan: BillingPlan): void =>
      _updateBillingPlan(store, plan),
  }))
);

function _updateBillingPlan(
  store: WritableStateSource<BillingPlanStateSignal>,
  plan: BillingPlan
): void {
  updateState(
    store,
    '[Billing] Update Plan',
    (state: BillingPlanStateSignal) => ({
      plan: {...state.plan, ...plan},
    })
  );
}
