import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'showOperator',
    standalone: true,
})
export class ShowOperatorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'eq':
        value = '=';
        break;
      case 'lt':
        value = '<';
        break;
      case 'gt':
        value = '>';
        break;
      case 'lte':
        value = '<=';
        break;
      case 'gte':
        value = '>=';
        break;
      case 'ne':
        value = '<>';
        break;
    }
    return value;
  }
}
