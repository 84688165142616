import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {lastValueFrom, Observable, of} from 'rxjs';
import {LoadingService} from './loading.service';
import {NotificationService} from './notification.service';
import {ReportingDatasetsService} from './reporting-datasets.service';

@Injectable({
  providedIn: 'root',
})
export class ResolverService  {
  constructor(
    private readonly http: HttpClient,
    private datasetService: ReportingDatasetsService,
    private loader: LoadingService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const calculationPath = 'calculations/:dsid/:id';
    if (route.routeConfig?.path === calculationPath) {
      const datasetId = route.url[1].path;
      const calculationId = route.url[2].path;
      this.loader.show('small');
      const calculation = await lastValueFrom(
        this.getCalculation(datasetId, calculationId),
        {defaultValue: false}
      ).catch(() => {
        this.notificationService.error('Error loading calculation', 5000);
        //this.router.navigate(['home']);
      });

      return calculation;
    }
    if (route.routeConfig?.path !== calculationPath) {
      return of({});
    }
  }

  getCalculation(datasetId, calculationId): Observable<any> {
    return this.datasetService.getCustomCalculation(datasetId, calculationId);
  }
}
