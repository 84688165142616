export enum PlanType {
  // Deprecated
  Agency = 'Agency',
  Freelance = 'Freelance',
  InHouse = 'InHouse',
  Free = 'Free',
  // New Plans
  Individual = 'Individual',
  Basic = 'Basic',
  Pro = 'Pro',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}
