import {inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

import {IServiceProviderConfiguration, ISSOConfig} from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  // Properties
  private httpClient: HttpClient = inject(HttpClient);
  private apiUrl = '/api/v2/idp/';

  // Request
  public getSSOSettings(orgId: string): Observable<ISSOConfig> {
    return this.httpClient.get<ISSOConfig>(`${this.apiUrl}${orgId}/`, {
      responseType: 'json',
    });
  }

  public setSSOSettings(payload: ISSOConfig): Observable<ISSOConfig> {
    return this.httpClient.post<ISSOConfig>(this.apiUrl, payload, {
      responseType: 'json',
    });
  }

  public updateSSOSettings(
    id: string,
    payload: ISSOConfig
  ): Observable<ISSOConfig> {
    return this.httpClient.post<ISSOConfig>(`${this.apiUrl}${id}/`, payload, {
      responseType: 'json',
    });
  }

  public getServiceProviderConfig(): Observable<IServiceProviderConfiguration> {
    return this.httpClient.get<IServiceProviderConfiguration>(
      `${this.apiUrl}sp-config/`,
      {responseType: 'json'}
    );
  }
}
