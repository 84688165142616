import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'dateAsAgo',
    standalone: true,
})
export class DateAsAgoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return `${this.translate.instant(
        'common.time_measurements.long_time_ago'
      )}`;
    }

    let time = (Date.now() - Date.parse(value)) / 1000;

    if (time < 10) {
      return `${this.translate.instant('common.time_measurements.just_now')}`;
    } else if (time < 60) {
      return `${this.translate.instant('common.time_measurements.moment_ago')}`;
    }

    const divider = [60, 60, 24, 30, 12];
    const string = [
      ` ${this.translate.instant('common.time_measurements.second')}`,
      ` ${this.translate.instant('common.time_measurements.minute')}`,
      ` ${this.translate.instant('common.time_measurements.hour')}`,
      ` ${this.translate.instant('common.time_measurements.day')}`,
      ` ${this.translate.instant('common.time_measurements.month')}`,
      ` ${this.translate.instant('common.time_measurements.year')}`,
    ];

    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }

    const language = localStorage.getItem('language');
    const plural =
      Math.floor(time) > 1 ? (string[i] === 'mes' ? 'es' : 's') : '';

    switch (language) {
      case 'en':
        return `${Math.floor(time)} ${
          string[i]
        }${plural} ${this.translate.instant(
          'common.time_measurements.ago_label'
        )}`;
      case 'es':
        return `${this.translate.instant(
          'common.time_measurements.ago_label'
        )} ${Math.floor(time)} ${string[i]}${plural}`;
    }
  }
}
