import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ClientData} from 'src/app/shared/models/client.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

import {ApiService} from './api.service';
import {Industry} from 'src/app/shared/models/industry.model';
import { setCharacterEscape } from 'src/app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  baseUrl = `${environment.apiUrl}clients/`;

  private businessTypes = [
    {
      value: 'lead_gen',
      desc: 'Lead Generation',
      ngxId:
        'company_selection.section.criteria.client.businessTypes.Lead_Generation',
    },
    {
      value: 'ecommerce',
      desc: 'E-Commerce',
      ngxId:
        'company_selection.section.criteria.client.businessTypes.E_Commerce',
    },
    {
      value: 'agency',
      desc: 'Marketing Agency',
      ngxId:
        'company_selection.section.criteria.client.businessTypes.Marketing_Agency',
    },
  ];

  private objectives = [
    {
      value: 'calls',
      desc: ' Phone Calls',
      ngxId: 'common.objectives.phoneCalls',
    },
    {
      value: 'forms',
      desc: ' Form Submissions',
      ngxId: 'common.objectives.formSubmissions',
    },
    {
      value: 'traffic',
      desc: ' Website Visits',
      ngxId: 'common.objectives.websiteVisits',
    },
    {
      value: 'transactions',
      desc: ' Online Sales',
      ngxId: 'common.objectives.onlineSales',
    },
    {
      value: 'visits',
      desc: ' Store Visits',
      ngxId: 'common.objectives.storeVisits',
    },
    {
      value: 'bookings',
      desc: ' Online Bookings',
      ngxId: 'common.objectives.onlineBookings',
    },
  ];

  private clientStatus = [
    {
      value: 'ACTIVE',
      viewValue: 'Active',
      ngxId: 'common.client_details.active',
    },
    {
      value: 'INACTIVE',
      viewValue: 'Inactive',
      ngxId: 'common.client_details.inactive',
    },
  ];

  private listOfCurrency = [
    {value: 'USD', viewValue: 'USD - United States Dollar'},
    {value: 'CAD', viewValue: 'CAD - Canadian Dollar'},
    {value: 'AUD', viewValue: 'AUD - Australian Dollar'},
    {value: 'EUR', viewValue: 'EUR - Euro'},
    {value: 'GBP', viewValue: 'GBP - British Pound Sterling'},
  ];

  private lifeCycle = [
    {
      value: 'PROSPECT',
      viewValue: 'Prospect',
      ngxId: 'common.lifecycle.prospect',
    },
    {
      value: 'CUSTOMER',
      viewValue: 'Customer',
      ngxId: 'common.lifecycle.customer',
    },
  ];

  private criteriaFields = [
    {label: 'Name', name: 'name', dataType: 'string'},
    {label: 'Website', name: 'website', dataType: 'string'},
    {label: 'Business Type', name: 'businessType', dataType: 'object'},
    {label: 'Industry', name: 'industry', dataType: 'string'},
    {label: 'Client Status', name: 'status', dataType: 'object'},
    {label: 'Lifecycle Stage', name: 'lifecycle', dataType: 'object'},
    {label: 'Owner', name: 'owner', dataType: 'string'},
    {label: 'Currency', name: 'currency', dataType: 'string'},
    {label: 'Parent Company', name: 'parentName', dataType: 'string'},
  ];

  private criteriaLogicOperators = [
    {operatorLabel: 'Equals (=)', operatorName: 'equals', dataType: 'string'},
    {
      operatorLabel: 'Not Equal (<>)',
      operatorName: 'notEqual',
      dataType: 'string',
    },
    {operatorLabel: 'Equals (=)', operatorName: 'in', dataType: 'object'},
    {operatorLabel: 'Not Equal (<>)', operatorName: 'nin', dataType: 'object'},
    {operatorLabel: 'Includes', operatorName: 'like', dataType: 'string'},
  ];

  public getClients(
    params?: any
  ): Observable<{clients: ClientData[]; total: number}> {
    return this.http.get<{clients: ClientData[]; total: number}>(this.baseUrl, {
      responseType: 'json',
      params: params,
    });
  }

  public getInstanceClients(
    orgId: string,
    params?: any
  ): Observable<{clients: ClientData[]; total: number}> {
    return this.http.get<{clients: ClientData[]; total: number}>(
      `${this.baseUrl}${orgId}/get-clients`,
      {responseType: 'json', params}
    );
  }

  public getClientDetails(id: string): Observable<ClientData> {
    return this.http.get<ClientData>(this.baseUrl + id + '/', {
      responseType: 'json',
    });
  }

  public deleteClient(id: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + id + '/');
  }

  public addNewClient(payload): Observable<ClientData> {
    return this.http.post<ClientData>(this.baseUrl, payload);
  }

  public updateClientDetails(id: string, payload: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + id + '/', payload, {
      responseType: 'json',
    });
  }

  public linkAccountToClient(id: string, payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}linkup-accounts/${id}/`,
      payload,
      {responseType: 'json'}
    );
  }

  public linkAccountsToClients(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}linkup-accounts-to-clients/`,
      payload,
      {responseType: 'json'}
    );
  }

  public sendClientAccessRequest(id: string, userId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}request-client-access/`, {
      id,
      userId,
    });
  }

  public getClientsTypeAhead(
    search: string,
    excludeCompany?: string
  ): Observable<{clients: ClientData[]; totals: number}> {
    return this.http.get<{clients: ClientData[]; totals: number}>(
      this.baseUrl,
      {
        params: excludeCompany
          ? {
              filter: `name contains ${setCharacterEscape(search)}`,
              excludeCompany,
              callFrom: 'mainClientList',
            }
          : {
              filter: `name contains ${setCharacterEscape(search)}`,
            },
        responseType: 'json',
      }
    );
  }

  public getIndustriesTypeAhead(search): Observable<Industry[]> {
    return this.http.get<Industry[]>(`${this.baseUrl}industries/`, {
      params: {query: search},
      responseType: 'json',
    });
  }

  public getBusinessTypes(): {value: string; desc: string; ngxId: string}[] {
    return this.businessTypes;
  }

  public getLifeCycle(): {value: string; viewValue: string; ngxId: string}[] {
    return this.lifeCycle;
  }

  public getClientStatus(): {
    value: string;
    viewValue: string;
    ngxId: string;
  }[] {
    return this.clientStatus;
  }

  public getCurrencyList(): {value: string; viewValue: string}[] {
    return this.listOfCurrency;
  }

  public getBusinessType(type: string): string | null {
    const businessType = this.businessTypes.find((t) => t.value === type);
    return businessType ? businessType.desc : null;
  }

  public getMarketingObjectives(): {
    value: string;
    desc: string;
    ngxId: string;
  }[] {
    return this.objectives;
  }

  public getCriteriaFields(): {
    label: string;
    name: string;
    dataType: string;
  }[] {
    return this.criteriaFields;
  }

  public getCriteriaLogicOperators(): {
    operatorLabel: string;
    operatorName: string;
    dataType: string;
  }[] {
    return this.criteriaLogicOperators;
  }

  public getValueMarketingObjectives(types): string[] {
    const objectives: string[] = [];
    types.forEach((type) => {
      const marketingObjective = this.objectives.find((t) => t.value === type);
      marketingObjective && objectives.push(marketingObjective.desc);
    });
    return objectives;
  }

  public getIndustriesHTTP(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/industries/`);
  }

  public moveReportClient(payload): Observable<any> {
    return this.apiService.post(`${this.baseUrl}mirror-data/`, payload);
  }
}
