import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'joinStringArray',
    standalone: true,
})
export class JoinStringArrayPipe implements PipeTransform {
  transform(value: string[] | string, separator = ', '): string {
    if (typeof value === 'string') {
      return value;
    }

    return value && value.length > 0 ? value.join(`${separator} `) : '';
  }
}
