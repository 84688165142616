import {Pipe, PipeTransform} from '@angular/core';

export const transformDateFunction = (value: number): string => {
  return new Date(value * 1000).toISOString().substr(11, 8);
};
@Pipe({
  name: 'transformDate',
  pure: false,
  standalone: true,
})
export class TransformDatePipe implements PipeTransform {
  transform(value: number): string {
    return transformDateFunction(value);
  }
}
