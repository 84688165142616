import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private theme = new BehaviorSubject<string>('dark');
  public readonly theme$ = this.theme.asObservable();

  constructor() {
    if (localStorage.getItem('theme')) {
      const value: string = localStorage.getItem('theme') as string;
      ThemeService.updateConfig(value);
      this.theme.next(value);
    } else {
      ThemeService.updateConfig('dark');
      this.theme.next('dark');
    }
  }

  /**
   * Set the value of the theme selected by the user.
   * @param isDark - is used to set theme value.
   */
  public setThemeScheme(isDark: boolean): void {
    if (isDark) {
      ThemeService.updateConfig('dark');
      this.theme.next('dark');
    } else {
      ThemeService.updateConfig('light');
      this.theme.next('light');
    }
  }

  private static updateConfig(value: string): void {
    localStorage.setItem('theme', value === 'dark' ? 'dark' : 'light');

    if (value === 'dark') {
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
    }
  }
}
