'use client';

import {Injectable} from '@angular/core';

type BannerIdType = string;

@Injectable({
  providedIn: 'root',
})
export class AdblockDetector {
  bannerIds: readonly BannerIdType[];

  constructor() {
    this.bannerIds = [
      'AdHeader',
      'AdContainer',
      'AD_Top',
      'homead',
      'ad-lead',
      'ad-box',
    ];
    this.init();
  }

  /**
   * Init library - add some tags to page with ads ids
   *
   * @returns {void} create detector instance
   * @memberof AdblockDetector
   */
  private init(): void {
    if (!this.isBrowser()) {
      console.error(
        'Detection on server side is not supported. Please use library only on client side.'
      );

      return;
    }

    const dataContainer: HTMLDivElement = document.createElement('div');

    dataContainer.innerHTML = this.generatesBannersString();

    document.body.append(dataContainer);
  }

  /**
   * Check enabling AdBlock
   *
   * @returns {Boolean} Status adblock enabling
   * @memberof AdblockDetector
   */
  public detect(): boolean {
    return !this.isBrowser()
      ? false
      : !this.bannerIds.every((bannerId) => this.checkVisibility(bannerId));
  }

  /**
   * Generate all ads blocks from ids dictionary
   *
   * @returns {String} Ads blocks
   * @private
   * @memberof AdblockDetector
   */
  private generatesBannersString(): string {
    return this.bannerIds
      .map((bannerId) => `<div id="${bannerId}"></div>`)
      .join('');
  }

  /**
   * Check visibility by banner id
   *
   * @param {Number} bannerId
   * @returns {HTMLElement|null} Return banners if AdBlock is not enabled
   * @private
   * @memberof AdblockDetector
   */
  checkVisibility(bannerId: BannerIdType): Element | null {
    const element = document.querySelector<HTMLDivElement>(`#${bannerId}`);

    if (element) {
      return element.offsetParent;
    }

    return null;
  }

  private isBrowser(): boolean {
    return typeof window !== 'undefined';
  }

  public saveLocalDetection(value: boolean): void {
    localStorage.setItem('showAdNotification', `${value ? 0 : 1}`);
  }

  public getLocalDetection(): boolean {
    const local = localStorage.getItem('showAdNotification');
    return local !== null ? local.toString() !== '0' : true;
  }
}
