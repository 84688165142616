import {Injectable, inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {User, Authentication, ChangePassword} from 'src/app/shared/models';
import {Session} from '../store/auth/auth.store';
import {Router} from '@angular/router';
import {ReportPresetColorsStore} from 'src/app/modules/reporting/state/report-preset-colors.store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public readonly fullApiUrl = environment.fullApiUrl;
  private readonly apiUrl = environment.apiUrl;
  private router = inject(Router);
  private reportPresetColors = inject(ReportPresetColorsStore);
  userInfo: Subject<User> = new Subject<User>();
  auth: Authentication;

  currentUserState$ = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) {}

  public getUserData(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}users/me/`, {
      responseType: 'json',
    });
  }

  public updateUser(payload: User | any): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}users/me/`, payload, {
      responseType: 'json',
    });
  }

  public refreshToken(payload: object): Observable<object> {
    return this.http.post<object>(`/core/refresh-token/`, payload, {
      responseType: 'json',
    });
  }

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('currentUser');
  }

  public isLoggedIn(): boolean {
    const sessionData = localStorage.getItem('session');
    if (sessionData) {
      this.auth = JSON.parse(sessionData);
    }

    return !!this.auth;
  }

  public getCurrentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  setCurrentUserState(payload: User): void {
    return this.currentUserState$.next(payload);
  }

  getCurrentUserState(): Observable<User> {
    return this.currentUserState$.asObservable();
  }

  public getToken(): string | null {
    return this.isLoggedIn() ? this.auth.access_token : null;
  }

  public getCurrenUrlSession(): Authentication | Session {
    const urlSession = this.router.parseUrl(
      localStorage.getItem('currentUrlSession') as string
    ).queryParams.session;
    return JSON.parse(urlSession);
  }

  public getSession(): Authentication | Session | null {
    const session = localStorage.getItem('session');
    return session ? JSON.parse(session) : null;
  }

  public setSession(auth: Authentication): void {
    auth.expires_at = new Date().getTime() + auth.expires_in * 1000;
    localStorage.setItem('session', JSON.stringify(auth));
  }

  public isTokenValid(session?: Authentication): boolean {
    return (
      new Date() <=
      new Date(session ? session.expires_at : this.auth.expires_at)
    );
  }

  public clearSession(): void {
    this.auth = {} as Authentication;
    localStorage.removeItem('session');
    localStorage.removeItem('loginAs');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUrlSession');
    localStorage.removeItem('organization');
    this.reportPresetColors.clearStorage();
  }

  public checkRole(roles: string[]): boolean {
    if (!this.getCurrentUser()) {
      return false;
    }

    return this.getCurrentUser()?.roles?.some((item: string) =>
      roles?.includes(item)
    );
  }

  public changeUserPassword(
    payload: ChangePassword
  ): Observable<ChangePassword> {
    return this.http.post<ChangePassword>(
      `${this.apiUrl}new-password`,
      payload
    );
  }

  public LogInAs(user: User): void {
    const payload = {email: user.email};
    let subDomainHomeURL;
    this.impersonate(payload).subscribe(
      (res) => {
        subDomainHomeURL = res;
      },
      () => {
        //
      },
      () => {
        const redirect = subDomainHomeURL.redirectURI;
        const queryString = encodeURI(JSON.stringify(this.auth));
        const currentUser: any = Object.assign({}, user);
        delete currentUser.userForm;
        this.userInfo.next(currentUser);
        window.location.replace(
          redirect + '?loginAs=' + user.email + '&session=' + queryString
        );
      }
    );
  }

  public impersonate(payload: object): Observable<User> {
    return this.http.post<any>(`${this.apiUrl}impersonate/`, payload, {
      responseType: 'json',
    });
  }

  public getPhoneCountryCodes(): Observable<{[key: string]: string}[]> {
    return this.http.get<{[key: string]: string}[]>(
      `${this.apiUrl}2fa/country-codes`,
      {responseType: 'json'}
    );
  }

  public enableTwoFactorAuthentication(phoneNumber: string): Observable<any> {
    const payload = {
      telephoneNumber: phoneNumber,
    };
    return this.http.post<any>(`${this.apiUrl}2fa/create/`, payload, {
      responseType: 'json',
    });
  }

  public disableTwoFactorAuthentication(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}2fa/deactivate/`, {
      responseType: 'json',
    });
  }

  public sendVerificationCode(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}2fa/send-code/`, {
      responseType: 'json',
    });
  }

  public verifyCode(verificationCode: string): Observable<any> {
    const payload = {verificationCode};
    return this.http.post<any>(`${this.apiUrl}2fa/consume-code/`, payload, {
      responseType: 'json',
    });
  }
}
