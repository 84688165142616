<header>
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="d-flex wsm-text-white mb-0">
      @if (data.showIcon) {
        <i class="fa-regular fa-circle-info me-1"></i>
      }
      {{ data.title }}
    </h4>

    <button
      (click)="onDismiss()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content class="mat-typography d-inline">
  @if (data?.innerHtml) {
    <div [innerHTML]="data?.innerHtml! | safeHtml"></div>
  }
  @if (!data?.innerHtml) {
    <p class="wsm-text-secondary work-sans wsm-big-body">
      {{ data.content }}
    </p>
  }
</mat-dialog-content>

<mat-dialog-actions
  class="justify-content-lg-end"
  [ngClass]="!data.isMobile ? 'justify-content-start' : 'justify-content-end'"
>
  <button
    (click)="onDismiss()"
    class="wsm-btn wsm-btn-outline me-3 mb-2 mb-lg-0"
  >
    {{
      data.button
        ? data.button
        : ("common.confirmation_dialog.cancel_button" | translate)
    }}
  </button>
  <button (click)="onConfirm()" class="wsm-btn wsm-btn-primary mb-2 mb-lg-0">
    {{
      data.confirmButton
        ? data.confirmButton
        : ("common.confirmation_dialog.accept_button" | translate)
    }}
  </button>
</mat-dialog-actions>
