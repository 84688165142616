<div class="wsm-snackbar-container position-relative">
  @if (!data.data.action) {
    <button
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon close-btn"
      (click)="snackBarRef.dismiss()"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  }

  @if (data.data.type === "success") {
    <div class="type success">
      <i class="fa-regular fa-check"></i> {{ "snackbar.success" | translate }}
    </div>
  }
  @if (data.data.type === "error") {
    <div class="type error">
      <i class="fa-regular fa-xmark"></i> {{ "snackbar.error" | translate }}
    </div>
  }
  @if (data.data.type === "warning") {
    <div class="type warning">
      <i class="fa-regular fa-triangle-exclamation"></i>
      {{ "snackbar.warning" | translate }}
    </div>
  }
  @if (data.data.type === "information") {
    <div class="type information">
      <i class="fa-regular fa-circle-info"></i>
      {{ "snackbar.information" | translate }}
    </div>
  }

  @if (!data.htmlMessage) {
    <div class="data">{{ data.data.message }}</div>
  }
  @if (data.htmlMessage) {
    <div class="data" [innerHtml]="htmlContent"></div>
  }

  <div class="d-flex justify-content-end">
    @if (data.data.action) {
      <button
        class="wsm-btn wsm-btn-primary wsm-btn-small mt-2"
        (click)="snackBarRef.dismissWithAction()"
      >
        {{ data.data.action }}
      </button>
    }
  </div>
</div>
