import {Injectable} from '@angular/core';
import {ImageSize} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private imageSizeFilters: Array<ImageSize> = [
    {
      property: 'cover',
      translation: 'reporting.widget_editor.style_form.image_cover_option',
    },
    {
      property: 'fill',
      translation: 'reporting.widget_editor.style_form.image_fill_option',
    },
    {
      property: 'contain',
      translation: 'reporting.widget_editor.style_form.image_contain_option',
    },
    {
      property: 'none',
      translation: 'reporting.widget_editor.style_form.image_none_option',
    },
  ];

  constructor() {
    //
  }

  public get getFontSizes(): Array<ImageSize> {
    return this.imageSizeFilters;
  }
}
