import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private showSidenavSource = new Subject<string>();
  showSidenavStream$ = this.showSidenavSource.asObservable();

  set showSidenav(value: string) {
    this.showSidenavSource.next(value);
  }
}
