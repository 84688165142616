import {PipeTransform, Pipe} from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: true
})
export class HighlightTextPipe implements PipeTransform {
  transform(text: string, search): string {
    if (!text) {
      return '';
    }
    let pattern;
    let regex;
    if (search) {
      pattern = search
        // eslint-disable-next-line no-useless-escape
        .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
        .split(' ')
        .filter((t) => t.length > 0)
        .join('|');

      regex = new RegExp(pattern, 'gi');
    }

    const highlightedText = search
      ? text.replace(regex, (match) => `<b>${match}</b>`)
      : text;
    return `<span>${highlightedText}</span>`;
  }
}
